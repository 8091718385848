import React, { useState, useEffect } from 'react';
import profile_ondiv from '../../tamplates/images/kluret_svgchat.svg';
import '../styling/user_settings.css'


function UserSettings() {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState(''); // Initialize an empty username
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmCode, setConfirmCode] = useState('');
    const [codeSent, setCodeSent] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        // Hide the scrollbar
        document.body.style.overflow = 'hidden';

        // Fetch user ID from local storage
        const storedUserID = localStorage.getItem('userID'); // No need for JSON.parse here

        if (storedUserID) {
            // Fetch user details from the API
            fetch('https://customerserver1-5d81976997ba.herokuapp.com/users/get-user-details/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // 'X-API-KEY': 'eiojwhd93reugh9euf0ehdovivfkcjgfheodfoeoigjeuffhuhooh',
                },
                body: JSON.stringify({ user_id: storedUserID }),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status === 'success') {
                        setEmail(data.email);
                        const nameFromEmail = data.email.split('@')[0];
                        setUsername(nameFromEmail.charAt(0).toUpperCase() + nameFromEmail.slice(1)); // Capitalize the first letter
                    } else {
                        setError(data.message);
                    }
                })
                .catch(error => {
                    setError('An error occurred while fetching user details.');
                });
        } else {
            setError('User ID not found in local storage.');
        }

        return () => {
            // Re-enable scrolling when the component unmounts
            document.body.style.overflow = 'auto';
        };
    }, []);

    const handleSendCode = () => {
        // Logic to send the confirmation code
        setCodeSent(true);
    };

    const handleUpdate = () => {
        // Handle password and email code confirmation logic here
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        if (!confirmCode) {
            setError('Please enter the confirmation code.');
            return;
        }

        // Here you can add the logic to update the password and confirm the email code

        setSuccess('User details updated successfully!');
        setError(null);
    };

    const handleLogout = () => {
        // Remove sessionToken and other user-related data from local storage
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('cartItems');
        localStorage.removeItem('totalCost');
        localStorage.removeItem('userID');

        // Reload the page after logout
        window.location.reload();
    };

    return (
        <>
            <div className="user_settings_ei2wu9rihjdghebiufb w-full mx-auto bg-black shadow-lg overflow-hidden">
                <div className="profile-header flex items-center mb-6">
                    {/* <img
                        src={profile_ondiv}
                        alt="User Avatar"
                        className="rounded-full object-cover mr-4"
                        style={{ width: '50px', height: '50px' }}
                    /> */}
                    <div>
                        <h2 className="text-2xl text-white">{username}</h2>
                    </div>
                </div>

                {error && <div className="text-red-500 mb-4">{error}</div>}
                {success && <div className="text-green-500 mb-4">{success}</div>}

                {/* User Data Section */}
                <div className="user-data bg-black mb-6">
                    <div className='__update_user_settings'>
                        <div className="mb-4">
                            <label className="block text-white">Email</label>
                            <input
                                type="email"
                                className="w-full mt-2 p-2"
                                value={email}
                                readOnly
                                id='___useremaildisplay__93jj4oef'
                                autoComplete="off"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-white">UserName</label>
                            <input
                                type="email"
                                className="w-full mt-2 p-2"
                                value={username}
                                readOnly
                                id='___useremaildisplay__93jj4oef'
                                autoComplete="off"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-white">Phone Number</label>
                            
                            <div id='___useremaildi44splay__93jj4oef3'>
                                <span>+46 </span>
                                <input
                                    type="phone"
                                    className="w-full mt-2 p-2"
                                    id=''
                                />
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block text-white">Country</label>
                            <input
                                type="email"
                                className="w-full mt-2 p-2"
                                value="Sweden"
                                readOnly
                                id='___useremaildisplay__93jj4oef'
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </div>

                
                <div className='bottom-div-display-user-settings-3940'>
                    <div className='button-49r940t-save-logout-45950'>
                        <button className='save-btn-493002'>
                            Save
                        </button>
                        <button
                            onClick={handleLogout}
                            className="w-full"
                            id='__logout_e30jf40rfj3if9p0je3wdde'
                            >
                                Log Out
                        </button>
                    </div>

                    {/* Bottom Links Section */}
                    <div className="bottom-links mt-8 text-center">
                        <a href="/policy" className="text-gray-500 hover:text-gray-700 mx-2">Terms and Conditions</a>
                        <a href="mailto:hej@kluret.se" className="text-gray-500 hover:text-gray-700 mx-2">Contact Us</a>
                    </div>
                </div>

                <br /><br /><br /><br />
            </div>
        </>
    );
}

export default UserSettings;
