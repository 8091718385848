import './register.css'
import continue_google_svg from '../tamplates/images/google.svg'
import Nav from '../loggedin/__main__/whitepaper_img/white/nav';


function Forgotpass() {


    return(
        <>
            <div className='VMXKvbSn1GtUekYy6WsH8c4axDr'>
                <div>
                    <Nav />
                </div>
                <div className='RUuSQKwNhptg3zJfGPbXAdBk5D'>
                    <div className='npG6QFDIwNjoWuSJ9l0edX'>
                        <div className='tY5Xjc1JHoCr9izRuxvFeDSOP2nkZ'>
                            <div className='WxJB57wmnpFdyVqjGhkluM'>
                                <div className='YskBZ6wc8R1GJnC9A5jdN'>
                                    <div className='g059WstZDzmphPqxCF37I2eJdO8'>
                                        <p>Forgot Password</p>
                                    </div>
                                    <div className='Un3erO1zQlmwBVDJv0ksZSoXg'></div>
                                </div>
                                <div className='hMiNHUqWmcLu0aBV9vnTAIg'>
                                    <input type="email" placeholder="Your Email" />
                                </div>

                                
                                <div className='lWVOiK6Zry5uc7ge4CjDUwhptR'>
                                    <div className='La2iSX6glFJAWKtE3uO7fhbmY4pIv'>
                                        <a className='JQTcxYpazANRCXmSe1B8E3VFvWZ6fU'>
                                            Get Code
                                        </a>
                                    </div>
                                    <div className='dki86woE2Yg4DONLHr3Zuvatc' onClick={() => window.location.href = '/login'}>
                                        <p><a>LogIn?</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Forgotpass;