import React, { useState } from 'react';
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from '../../firebase'; // Import Firebase configuration

function Google_auth() {
    const [user, setUser] = useState(null); // Store user info after successful registration
    const [errorMessage, setErrorMessage] = useState(''); // Error message handling

    // Google Sign-In Function
    const handleGoogleSignUp = async () => {
        try {
            // Trigger Google sign-in popup
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;

            // Get the Firebase ID token
            const idToken = await user.getIdToken();

            // Send the ID token and user info to your Django backend
            const response = await fetch('http://127.0.0.1:8008/auth_google/google_register/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,  // Include ID token in Authorization header
                },
                body: JSON.stringify({
                    email: user.email,
                    displayName: user.displayName,
                    uid: user.uid,
                }),
            });

            if (response.ok) {
                // Set the user info for rendering on successful registration
                setUser({
                    email: user.email,
                    displayName: user.displayName,
                    uid: user.uid,
                });
            } else {
                const data = await response.json();
                setErrorMessage(data.message || "Failed to register with Google.");
            }
        } catch (error) {
            console.error("Google Sign-In Error:", error);
            setErrorMessage("Google Sign-In failed. Please try again.");
        }
    };

    return (
        <div className='__loginwithgoogle__'>
            {/* Register with Google Button */}
            <button onClick={handleGoogleSignUp}>
                Register with Google
            </button>

            {/* Render user information after successful registration */}
            {user && (
                <div>
                    <h2>Welcome, {user.displayName}!</h2>
                    <p>Email: {user.email}</p>
                    <p>User ID: {user.uid}</p>
                </div>
            )}

            {/* Display error messages if any */}
            {errorMessage && <div>{errorMessage}</div>}
        </div>
    );
}

export default Google_auth;
