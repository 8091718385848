// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Your Firebase configuration (from Firebase Console)
const firebaseConfig = {
  apiKey: "AIzaSyBeDlcobCjn47UMdhGmmEImWAUN8FX99NU",  // Your Web API Key
  authDomain: "users-95da3.firebaseapp.com",
  databaseURL: "https://users-95da3-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "users-95da3",
  storageBucket: "users-95da3.appspot.com",
  messagingSenderId: "569647050499",
  appId: "1:569647050499:web:a209b547e2c6ff8b5b4336",
  measurementId: "G-MZVB9JCQDV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
