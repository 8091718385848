import React, { useState } from 'react';
import logo_svg from '../tamplates/images/logo.svg';
import './Navout.css';

function Navout() {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    return (
        <>
            <div className={`kwgB9qHAOncLX7REjdeDTr ${dropdownVisible ? 'dropdown-visible' : ''}`}>
                <div className='jiuhigyutfyihohugu'>
                    <div className='jiuhiyguyioijhgiyfutfuygiu'>
                        <div className={`ZrEfO3xRi02T4eMcwGd7HqV8g ${dropdownVisible ? 'active' : ''}`}>
                            <div className='ElqXTbFmyKg3pi4nUd6Ou7YC'>
                                <a href="/"><img src={logo_svg} alt="" /></a>
                            </div>
                            <div className='mhonu3R6YktWzp0icgr7d4a5bEGvw'>
                                <div className='k49nuGoqaUcjIOplJTFy'>
                                    <div className='YlOZ4jPn2RorUFBi9qWDwM7TbHxtQ'>
                                        <div><a href="">About Kluret</a></div>
                                        <div><a href="">Support</a></div>
                                        <div><a href="">Join Us</a></div>
                                    </div>
                                </div>
                                <div className='WCzrRE0gsH7DXlocMIm9vY3ety'>
                                    <div><a href="/register">Register</a></div>
                                    <div><a href="/login">LogIn</a></div>
                                </div>
                            </div>
                            <div className='dropdown_menu' onClick={toggleDropdown}>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    
                        {dropdownVisible && (
                            <div id='dropdown_93842992'>
                                <div className='ijuiygdowrioer'>
                                    <div><a href="">About Kluret</a></div>
                                    <div><a href="">Kluret Features</a></div>
                                    <div><a href="">Kluret Blog</a></div>
                                    <div><a href="">Kluret Support</a></div>
                                    <div><a href="">Latest Updates</a></div>
                                    <div><a href="">FAQs</a></div>
                                    <div><a href="">Contact Kluret</a></div>
                                </div>
                                <div className='oiphigyuhoijopihogiyo'>
                                    <div><a href="/register" className='register_oifj'>Register</a></div>
                                    <div><a href="/login" className='login_984u3'>LogIn</a></div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navout;
