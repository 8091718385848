import React, { useState } from "react";
import Nav from "../nav";
import Bottomnav from "./botom_nav";
import faq_svg from './img/faq.svg';
import './style/outlet.css';

function Faqs() {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        { question: "What is Kluret?", answer: "Kluret is an advanced AI-powered search engine that helps users find products across European online platforms, enhancing e-commerce searches." },
        { question: "How does Kluret help with product searches?", answer: "Kluret allows users to quickly search across multiple online platforms, streamlining the process of finding products." },
        { question: "Is Kluret available outside Europe?", answer: "Currently, Kluret focuses on the European market, but there are plans for future expansions." },
        { question: "How does Kluret source its product data?", answer: "Kluret aggregates data from multiple e-commerce sites, ensuring up-to-date product availability and accurate information." },
        { question: "What sets Kluret apart from other search engines?", answer: "Kluret’s focus on the European e-commerce market and its AI-driven algorithms make searches more accurate and relevant for users." },
        { question: "Does Kluret offer filters for product categories?", answer: "Yes, Kluret provides a range of filters, including price, brand, and category, to narrow down search results." },
        { question: "Can Kluret compare prices between different stores?", answer: "Kluret allows users to compare prices across different online stores to help find the best deal." },
        { question: "How secure is the data I enter on Kluret?", answer: "Kluret prioritizes user privacy and uses encryption and data protection measures to keep user information secure." },
        { question: "Is Kluret free to use?", answer: "Yes, Kluret is a free service for users who want to find products across European e-commerce sites." },
        { question: "Does Kluret support all EU languages?", answer: "Kluret supports multiple European languages, ensuring that users across the EU can access the platform in their preferred language." },
        { question: "How accurate are Kluret's search results?", answer: "Kluret’s AI algorithms provide highly accurate and relevant search results, continuously learning from user behavior." },
        { question: "Can I save my search results on Kluret?", answer: "Kluret allows users to save search results and revisit them later, making it easier to track products." },
        { question: "Does Kluret provide user reviews on products?", answer: "Yes, Kluret includes user reviews and ratings to help shoppers make informed decisions." },
        { question: "What types of products can I find using Kluret?", answer: "Kluret covers a wide range of products, including electronics, fashion, home goods, and more." },
        { question: "Is there a mobile app for Kluret?", answer: "Kluret currently focuses on its web platform, but a mobile app may be released in the future." },
        { question: "How often does Kluret update its product listings?", answer: "Kluret frequently updates its product listings to ensure users have access to the latest information." },
        { question: "Does Kluret include shipping information in search results?", answer: "Yes, Kluret often includes shipping costs and options in its search results to provide full transparency." },
        { question: "Can I search for second-hand products on Kluret?", answer: "Kluret supports searches for both new and second-hand products, helping users find what they need." },
        { question: "How can I provide feedback about Kluret?", answer: "Users can provide feedback via Kluret’s website, helping the platform improve its services." },
        { question: "Is Kluret linked to specific retailers?", answer: "Kluret works with multiple retailers but remains independent, ensuring unbiased search results." },
        { question: "Can Kluret track product availability?", answer: "Kluret keeps track of product availability in real-time, notifying users when products are back in stock." },
        { question: "How do I report incorrect information on Kluret?", answer: "Users can report inaccuracies via the platform, ensuring that product data remains up to date." },
        { question: "Can I customize my search preferences on Kluret?", answer: "Kluret allows users to customize search settings based on preferences like location, price range, and category." },
        { question: "How fast is the search process on Kluret?", answer: "Kluret’s AI ensures a fast and efficient search process, returning results in seconds." },
        { question: "Is there a way to track price changes over time?", answer: "Yes, Kluret provides features for tracking price changes, helping users decide the best time to purchase." },
        { question: "Does Kluret support product alerts for price drops?", answer: "Kluret allows users to set alerts for price drops on their favorite products." },
        { question: "Can Kluret help with international shipping?", answer: "Kluret provides information on international shipping options where available, helping users with cross-border purchases." },
        { question: "Does Kluret offer discounts or promo codes?", answer: "Kluret occasionally displays discounts or promo codes available for specific products." },
        { question: "Can I find rare or limited products using Kluret?", answer: "Kluret helps users find hard-to-find and limited-edition products across various platforms." },
        { question: "How do I contact Kluret for support?", answer: "Users can contact Kluret via their support page for any inquiries or issues." }
    ];

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);  // Toggle between current and clicked FAQ
    };

    return (
        <>
            <div className="__faqs_329jrwje">
                <div>
                    <Nav />
                </div>
                <div className="fjdofiedifgegduhtgf4righdd">
                    <div className="__faq__topic_39jf94j">
                        <h1>Frequently Asked Questions</h1>
                    </div>
                    <div className="__faq__topic_39jf94j_3uery8fdhi">
                        <div>
                            <ul className="__allthefaqs__">
                                {faqs.map((faq, index) => (
                                    <li key={index} className="__lis_fwdifwsjdiwr">
                                        <button className="flex gap-2 items-center w-full py-5 text-base font-semibold text-left md:text-lg border-base-content/10" aria-expanded={activeIndex === index} onClick={() => toggleFAQ(index)}>
                                            <span className="flex-1 text-base-content">{faq.question}</span>
                                            <svg className="flex-shrink-0 w-4 h-4 ml-auto fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <rect y="7" width="16" height="2" rx="1" className="transform origin-center transition duration-200 ease-out"></rect>
                                                <rect y="7" width="16" height="2" rx="1" className="transform origin-center rotate-90 transition duration-200 ease-out"></rect>
                                            </svg>
                                        </button>
                                        <div className={`transition-all duration-300 ease-in-out overflow-hidden ${activeIndex === index ? 'max-h-full' : 'max-h-0'}`} id="__description___eh8wdf">
                                            <div className="pb-5 leading-relaxed">
                                                <div className="space-y-2 leading-relaxed p-1">{faq.answer}</div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <Bottomnav />
                </div>
            </div>
            <style jsx>{`
                @layer utilities {
                    @keyframes gradientChange {
                        0% {
                            background-position: 0%;
                        }
                        50% {
                            background-position: 100%;
                        }
                        100% {
                            background-position: 0%;
                        }
                    }
                    .gradient {
                        background: linear-gradient(90deg, blue, red);
                        background-size: 200% 200%;
                    }
                    .element-to-rotate {
                        animation: gradientChange 4s ease infinite;
                    }
                }
            `}</style>
        </>
    );
}

export default Faqs;
