import React, { useEffect, useState } from 'react';
import '../styling/cart_template.css';
import svg_back_close from './whitepaper_img/svg_back_white.svg'


function CartTemplate({ onClose }) {
    const [cartItems, setCartItems] = useState([]);
    const [totalCost, setTotalCost] = useState(0);
    const [isLoading, setIsLoading] = useState(false); // Added state for loading animation

    const fetchCartData = async () => {
        const sessionToken = localStorage.getItem('sessionToken');
        const userID = localStorage.getItem('userID');
        const tokenData = sessionToken ? JSON.parse(sessionToken) : null;
        const isLoggedIn = tokenData && tokenData.expiryTime > Date.now() && userID;

        if (isLoggedIn) {
            try {
                const response = await fetch('https://customerserver1-5d81976997ba.herokuapp.com/addcart/getcart/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        // 'X-API-KEY': 'eiojwhd93reugh9euf0ehdovivfkcjgfheodfoeoigjeuffhuhooh',
                    },
                    body: JSON.stringify({ user_id: userID }),
                });

                if (response.ok) {
                    const data = await response.json();
                    const aggregatedItems = aggregateCartItems(data.cart_items || []);
                    setCartItems(aggregatedItems);
                    calculateTotalCost(aggregatedItems);
                    saveCartState(aggregatedItems, totalCost);
                } else {
                    console.error('Failed to fetch cart data');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            console.error('User is not logged in or session expired');
        }
    };

    const aggregateCartItems = (items) => {
        const itemMap = new Map();

        items.forEach(item => {
            if (itemMap.has(item.product_url)) {
                const existingItem = itemMap.get(item.product_url);
                existingItem.quantity += 1;
                existingItem.totalPrice = (parseFloat(existingItem.totalPrice) + parseFloat(item.product_price.replace(' kr', '').replace(',', '.'))).toFixed(2);
            } else {
                itemMap.set(item.product_url, {
                    ...item,
                    quantity: 1,
                    totalPrice: parseFloat(item.product_price.replace(' kr', '').replace(',', '.')).toFixed(2)
                });
            }
        });

        return Array.from(itemMap.values());
    };

    const calculateTotalCost = (items) => {
        const total = items.reduce((sum, item) => sum + parseFloat(item.totalPrice), 0);
        setTotalCost(total.toFixed(2));
    };

    const updateQuantity = (product_url, newQuantity) => {
        const updatedItems = cartItems.map(item => {
            if (item.product_url === product_url) {
                const newTotalPrice = (parseFloat(item.product_price.replace(' kr', '').replace(',', '.')) * newQuantity).toFixed(2);
                return { ...item, quantity: newQuantity, totalPrice: newTotalPrice };
            }
            return item;
        });

        setCartItems(updatedItems);
        calculateTotalCost(updatedItems);
    };

    const removeFromCart = async (product_url) => {
        const sessionToken = localStorage.getItem('sessionToken');
        const userID = localStorage.getItem('userID');
        const tokenData = sessionToken ? JSON.parse(sessionToken) : null;
        const isLoggedIn = tokenData && tokenData.expiryTime > Date.now() && userID;

        if (isLoggedIn) {
            try {
                const response = await fetch('https://customerserver1-5d81976997ba.herokuapp.com/addcart/remove-from-cart/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        // 'X-API-KEY': 'eiojwhd93reugh9euf0ehdovivfkcjgfheodfoeoigjeuffhuhooh',
                    },
                    body: JSON.stringify({ user_id: userID, product_url: product_url }),
                });

                if (response.ok) {
                    fetchCartData();
                } else {
                    console.error('Failed to remove item from cart');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            console.error('User is not logged in or session expired');
        }
    };

    const saveCartState = (items, total) => {
        localStorage.setItem('cartItems', JSON.stringify(items));
        localStorage.setItem('totalCost', total);
    };

    const loadCartState = () => {
        const savedCartItems = JSON.parse(localStorage.getItem('cartItems'));
        const savedTotalCost = localStorage.getItem('totalCost');

        if (savedCartItems && savedTotalCost) {
            setCartItems(savedCartItems);
            setTotalCost(savedTotalCost);
        } else {
            fetchCartData();
        }
    };

    const sendOrderToAPI = async (paymentLink, paymentIntentId) => {  // Accept paymentIntentId as an argument
        const userID = localStorage.getItem('userID');
        try {
            for (const item of cartItems) {
                await fetch('https://usbaiclient-bc9c07737d7c.herokuapp.com/users_order/add-user-order/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_id: userID,
                        product_name: item.product_name,
                        product_url: item.product_url,
                        product_price: item.totalPrice,
                        quantity: item.quantity,  // Include quantity
                        payment_link: paymentLink,  // Send the payment link
                        payment_intent_id: paymentIntentId  // Send the payment intent ID
                    }),
                });
            }
            console.log('Order sent to API successfully.');
        } catch (error) {
            console.error('Failed to send order to API:', error);
        }
    };

    const handlePayment = async () => {
        setIsLoading(true);  // Show loading animation when clicked
        const sessionToken = localStorage.getItem('sessionToken');
        const userID = localStorage.getItem('userID');
        const tokenData = sessionToken ? JSON.parse(sessionToken) : null;
        const isLoggedIn = tokenData && tokenData.expiryTime > Date.now() && userID;
    
        if (isLoggedIn && cartItems.length > 0) {
            const productDescription = cartItems.map(item => `${item.product_name} (x${item.quantity})`).join(', ');
    
            try {
                const response = await fetch('https://customerserver1-5d81976997ba.herokuapp.com/kluret_stripe/collect-data/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        // 'X-API-KEY': 'eiojwhd93reugh9euf0ehdovivfkcjgfheodfoeoigjeuffhuhooh',
                    },
                    body: JSON.stringify({
                        user_id: userID,
                        product_description: productDescription,
                        total_cost: totalCost,
                        total_products: cartItems.reduce((sum, item) => sum + item.quantity, 0),
                    }),
                });
    
                if (response.ok) {
                    const data = await response.json();
                    if (data.status === "success") {
                        // Send the order to the API before redirecting to payment
                        await sendOrderToAPI(data.payment_link, data.payment_intent_id);

                        saveCartState(cartItems, totalCost);
                        // Redirect the user to the payment link in the same tab
                        window.location.href = data.payment_link;
                    } else {
                        console.error('Failed to create payment link');
                    }
                } else {
                    console.error('Failed to process payment');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            console.error('User is not logged in or session expired, or cart is empty');
        }
        setIsLoading(false);  // Hide loading animation after processing
    };

    useEffect(() => {
        loadCartState();

        const intervalId = setInterval(fetchCartData, 500);
        return () => clearInterval(intervalId);
    }, []);

    const truncateWords = (text, numWords) => {
        const words = text.split(" ");
        if (words.length > numWords) {
            return words.slice(0, numWords).join(" ") + "...";
        } else {
            return text;
        }
    };

    // New function to format prices with space as a thousand separator
    const formatPrice = (price) => {
        return new Intl.NumberFormat('sv-SE', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(price);
    };

    return (
        <div className="h-[100vh] sm:h-[100svh] flex flex-col overflow-hidden" id='jidhuygisjdkgoshirfugkheidhkigjhdsofsiuhjg______944333322'>
            <div className="sm:flex shadow-md flex-grow overflow-hidden">
                <div className="w-full sm:w-3/4 bg-black px-3 py-10 relative overflow-y-auto" id='__producttemplate__39jrhjfh9efd_div_center'>
                    <button 
                        onClick={onClose} 
                        id='__closecart1oedj__jds9d4e0'
                        className="text-white hover:text-white"
                    >
                        <img src={svg_back_close} alt="" />
                    </button>

                    {cartItems.map((item, index) => (
                        <div key={index} className="flex items-center py-4" id='jihucrhogdhfojhrwishfiojgwrighedfgeifgciefsguedfhgo______dsgjhier'>
                            <div 
                                className="w-20 h-20 flex-shrink-0 overflow-hidden rounded-md bg-gray-100" 
                                style={{ backgroundImage: `url(${item.product_image})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
                            >
                            </div>

                            <div className="ml-4 flex flex-1 flex-col">
                                <div className="flex justify-between" id='___idhugysheigudhijfhuwbedhifu'>
                                    <h3>{truncateWords(item.product_name, 3)}</h3>
                                    <p className="pricing-total-product-499">{formatPrice(item.totalPrice)} kr</p> {/* Applied formatPrice */}
                                </div>
                                <div className="flex items-center justify-between mt-2">
                                    <p>Total of unit(s) <span className='unity-492002004-4929'>{item.quantity}</span></p>
                                    <button 
                                        className="delete-button-490203"
                                        onClick={() => removeFromCart(item.product_url)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div id="summary" className="w-full sm:w-1/4 md:w-1/2 px-3 py-10 overflow-y-auto">
                    <h1 className="font-semibold text-2xl pb-8">Order Summary</h1>
                    <div className="flex justify-between mt-10 mb-5">
                        <span className="font-semibold text-sm uppercase">{cartItems.length} Item(s)</span>
                    </div>
                    <div className="mt-8">
                        <div className="flex font-semibold justify-between py-6 text-sm uppercase">
                            <span>Total cost</span>
                            <span>{formatPrice(totalCost)} kr</span> {/* Applied formatPrice */}
                        </div>
                        <div className="flex font-semibold justify-between py-6 text-sm uppercase">
                            <span>Shipping</span>
                            <span className='shipping_charge_93jere9sj'>Free</span>
                        </div>
                        <button 
                            className="font-semibold py-3 text-sm text-white uppercase w-full"
                            onClick={handlePayment}
                            disabled={isLoading}
                            id="___2clicktoproceed"
                        >
                            {isLoading ? (
                                <svg
                                    className="animate-spin h-5 w-5 text-white mx-auto"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            ) : (
                                "Checkout"
                            )}
                        </button>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        </div>
    );
}

export default CartTemplate;
