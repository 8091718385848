import React, { useState } from "react";
import Bottomnav from "./botom_nav";
import Nav from "../nav";
import './style/outlet.css';

function Termsconditions() {

    // State to handle the toggling of each section
    const [showFullText, setShowFullText] = useState({
        section1: false,
        section2: false,
        section3: false,
        section4: false,
        section5: false,
        section6: false,
        section7: false,
        section8: false,
        section9: false,
        section10: false,
        section11: false,
    });

    const toggleText = (section) => {
        setShowFullText({ ...showFullText, [section]: !showFullText[section] });
    };

    const renderContent = (content, section) => {
        const shortText = content.split(' ').slice(0, 100).join(' ') + '...';
        return (
            <>
                <p className="mb-4">{showFullText[section] ? content : shortText}</p>
                <button 
                    className="text-blue-500" 
                    onClick={() => toggleText(section)}>
                    {showFullText[section] ? 'View Less' : 'View All'}
                </button>
            </>
        );
    };

    return (
        <>
            <div>
                <Nav />
                <div>
                    <br /><br /><br />
                </div>
                <div className="ourteam-at-kluret">
                    <div className="main_header_policy">
                        <div>
                            <h1 className="text-4xl font-bold mb-6">Kluret Terms and Conditions</h1>
                        </div>
                        <div>
                            <p>Welcome to Kluret! By accessing or using our website and services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully, as they outline your rights and obligations when using Kluret.</p>
                        </div>
                    </div>
                    <div className="policy_nest_3004ij0kr">
                        
                        <div className="__policy_diiv">
                            <h2 className="text-2xl font-semibold mb-4">Acceptance of Terms</h2>
                            {renderContent(
                                `By accessing and using Kluret's search engine and services, you accept and agree to be bound by these Terms and Conditions, as well as our Privacy Policy. If you do not agree with any part of these terms, you should not use our website or services. We reserve the right to modify these terms at any time, and your continued use of Kluret constitutes your acceptance of such changes.
                                Kluret Chat: Here`, 
                                'section1')}
                        </div>

                        <div className="__policy_diiv">
                            <h2 className="text-2xl font-semibold mb-4">Use of Services</h2>
                            {renderContent(
                                `Kluret provides an AI-powered search engine specifically designed for the Swedish market. Our platform enables users to find products across various e-commerce websites. The core functionalities include:
                                - Chatting with the AI to assist in finding the right products.
                                - Browsing and comparing product listings.
                                - Receiving detailed product information through the Kluret Product Assist feature.
                                You may use our services only for lawful purposes and in accordance with these Terms. Any misuse of our platform, including automated scraping, harmful interference, or spamming, is strictly prohibited.`, 
                                'section2')}
                        </div>

                        <div className="__policy_diiv">
                            <h2 className="text-2xl font-semibold mb-4">User Responsibilities</h2>
                            {renderContent(
                                `When using Kluret, you are responsible for:
                                - Providing accurate information in your interactions with the AI.
                                - Ensuring the content of your communications with the AI is appropriate and lawful.
                                - Not engaging in activities such as posting false information, using offensive language, or attempting to exploit the AI for malicious purposes.
                                Kluret reserves the right to suspend or terminate access for users who violate these responsibilities.`, 
                                'section3')}
                        </div>

                        <div className="__policy_diiv">
                            <h2 className="text-2xl font-semibold mb-4">Privacy Policy</h2>
                            {renderContent(
                                `Your privacy is of utmost importance to us. By using Kluret, you agree to our collection and use of personal information as outlined in our Privacy Policy. We are committed to ensuring that your data is securely stored and used in compliance with all applicable data protection regulations, including the General Data Protection Regulation (GDPR).
                                We collect and use personal data to improve your experience, including enhancing AI responses, customizing your search experience, and maintaining the integrity of our platform. You can review the full Privacy Policy for detailed information on how we handle your data.`, 
                                'section4')}
                        </div>

                        <div className="__policy_diiv">
                            <h2 className="text-2xl font-semibold mb-4">Intellectual Property</h2>
                            {renderContent(
                                `All content on Kluret, including text, graphics, logos, AI algorithms, and software, is the exclusive property of Kluret or its licensors. This intellectual property is protected by copyright, trademark, and other laws. You may not use, copy, reproduce, or distribute any of the materials from Kluret without prior written consent from us.
                                Unauthorized use of our intellectual property may result in legal action. You are granted a limited, revocable, non-exclusive license to use Kluret for personal and non-commercial purposes, provided you do not violate these Terms.`, 
                                'section5')}
                        </div>

                        <div className="__policy_diiv">
                            <h2 className="text-2xl font-semibold mb-4">Limitation of Liability</h2>
                            {renderContent(
                                `Kluret provides its services on an "as is" basis. While we strive to deliver accurate and relevant search results, we do not guarantee the completeness or accuracy of the information provided. Users are advised to verify product details and pricing before making any purchases.
                                Kluret is not liable for any damages, losses, or other liabilities arising from your use of the platform, including but not limited to the accuracy of AI-generated recommendations, product descriptions, or third-party links. You agree to use the service at your own risk.`, 
                                'section6')}
                        </div>

                        <div className="__policy_diiv">
                            <h2 className="text-2xl font-semibold mb-4">Third-Party Links</h2>
                            {renderContent(
                                `Kluret may include links to third-party websites, including e-commerce platforms and other external resources. These links are provided for convenience, and we do not endorse or take responsibility for the content, products, or services offered by these third parties. Users are encouraged to review the terms and privacy policies of any third-party sites they visit.`, 
                                'section7')}
                        </div>

                        <div className="__policy_diiv">
                            <h2 className="text-2xl font-semibold mb-4">Modifications to Terms</h2>
                            {renderContent(
                                `Kluret reserves the right to modify these Terms and Conditions at any time. Changes will be posted on this page, and users will be notified of significant changes through email or on-site notifications. It is your responsibility to review the updated terms regularly.
                                Continued use of Kluret after any changes signifies your acceptance of the modified Terms and Conditions. If you do not agree with the changes, you should discontinue using our services immediately.`, 
                                'section8')}
                        </div>

                        <div className="__policy_diiv">
                            <h2 className="text-2xl font-semibold mb-4">Termination</h2>
                            {renderContent(
                                `We reserve the right to suspend or terminate your access to Kluret at any time, without prior notice, if you breach these Terms or engage in any activity that disrupts or harms our platform. You may also choose to stop using our services at any time. Upon termination, all rights granted to you under these Terms will cease.`, 
                                'section9')}
                        </div>

                        <div className="__policy_diiv">
                            <h2 className="text-2xl font-semibold mb-4">Governing Law</h2>
                            {renderContent(
                                `These Terms and Conditions are governed by and construed in accordance with the laws of Sweden. Any disputes arising from or related to these Terms shall be subject to the exclusive jurisdiction of the courts located in Stockholm, Sweden.`, 
                                'section10')}
                        </div>

                        <div className="__policy_diiv">
                            <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
                            {renderContent(
                                `If you have any questions, concerns, or feedback about these Terms and Conditions or Kluret's services, please feel free to contact us:
                                - Email: hej@kluret.com`, 
                                'section11')}
                        </div>
                    </div>
                </div>
                
                <div className="__bottom__399fr9j">
                    <Bottomnav />
                </div>
            </div>
        </>
    )
}

export default Termsconditions;
