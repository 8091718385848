import React, { useState } from 'react';

function getCSRFToken() {
  const csrfToken = document.cookie.split(';').find(cookie => cookie.trim().startsWith('csrftoken='));
  return csrfToken ? csrfToken.split('=')[1] : '';
}

function ProductSearch() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState('');
  const [pageIndex, setPageIndex] = useState(1);

  const fetchProducts = (newPageIndex) => {
    setLoading(true);
    
    const formData = new FormData();
    formData.append('product_name', productName);
    formData.append('page_index', newPageIndex);

    fetch('https://webnode-9662dc9a689b.herokuapp.com/openai_google_computing/jdb/', {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRFToken': getCSRFToken(),  // Include CSRF token in the headers
      },
    })
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data) && data.length > 0) {
          setProducts(prevProducts => [...prevProducts, ...data]);
        } else {
          console.warn("No products found or response is not an array.");
        }
        setLoading(false);
        setPageIndex(newPageIndex);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  const handleSearch = () => {
    setProducts([]);
    fetchProducts(1);
  };

  const loadMore = () => {
    fetchProducts(pageIndex + 1);
  };

  return (
    <div className="bg-gray-100 min-h-screen p-4">
      <div className="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold mb-4 text-center">Product Search</h1>
        <div className="flex items-center mb-4">
          <input
            type="text"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            placeholder="Enter product name"
            className="flex-grow p-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={handleSearch}
            disabled={loading}
            className={`p-2 bg-blue-500 text-white rounded-r-md hover:bg-blue-600 ${loading ? 'cursor-not-allowed' : ''}`}
          >
            {loading ? 'Searching...' : 'Search'}
          </button>
        </div>

        <ul className="space-y-4">
          {products.map((product, index) => (
            <li key={index} className="bg-white p-4 rounded-lg shadow-md flex items-center space-x-4">
              <img src={product.cover_image_url} alt={product.name} className="w-16 h-16 object-cover rounded-md" />
              <div className="flex-grow">
                <h2 className="text-lg font-semibold">{product.name}</h2>
                <p className="text-gray-600">{product.price}</p>
              </div>
              <a
                href={product.product_page_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                View Product
              </a>
            </li>
          ))}
        </ul>

        {products.length > 0 && (
          <div className="flex justify-center mt-6">
            <button
              onClick={loadMore}
              disabled={loading}
              className={`p-2 bg-green-500 text-white rounded-md hover:bg-green-600 ${loading ? 'cursor-not-allowed' : ''}`}
            >
              {loading ? 'Loading...' : 'Load More'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductSearch;
