import React, { useState } from 'react';
import './register.css';  // Assuming this file contains your CSS styles
import continue_google_svg from '../tamplates/images/google.svg';
import Nav from '../loggedin/__main__/whitepaper_img/white/nav';

function LogIn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleLogin = async () => {
        try {
            const response = await fetch('https://customerserver1-5d81976997ba.herokuapp.com/users/login_authorizer/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // 'X-API-KEY': 'eiojwhd93reugh9euf0ehdovivfkcjgfheodfoeoigjeuffhuhooh',
                },
                body: JSON.stringify({ email, password }),
            });
    
            const data = await response.json();
    
            if (response.ok) {
                if (data.status === "success") {
                    // Set session token in localStorage with expiry time
                    const expiryTime = Date.now() + 10000000000000; // 10 seconds from now (example)
                    localStorage.setItem('sessionToken', JSON.stringify({ token: data.token, expiryTime }));

                    // Store user ID in local storage
                    localStorage.setItem('userID', data.user_id);

                    // Redirect to /
                    window.location.href = '/chat';

                } else {
                    setErrorMessage(data.message || 'Incorrect password');
                }
            } else {
                setErrorMessage(data.message || 'User not found');
            }
        } catch (error) {
            console.error('Error logging in:', error);
            setErrorMessage('Failed to log in. Please try again.');
        }
    };
    
    return (
        <>
            <div className='VMXKvbSn1GtUekYy6WsH8c4axDr'>
                <div className='__nav__sectoin__ewj93r93jrodk'>
                    <Nav />
                </div>
                <div className='RUuSQKwNhptg3zJfGPbXAdBk5D'>
                    <div className='npG6QFDIwNjoWuSJ9l0edX'>
                        <div className='tY5Xjc1JHoCr9izRuxvFeDSOP2nkZ'>
                            <div className='WxJB57wmnpFdyVqjGhkluM'>
                                <div className='YskBZ6wc8R1GJnC9A5jdN'>
                                    <div className='g059WstZDzmphPqxCF37I2eJdO8'>
                                        <p>LogIn</p>
                                    </div>
                                    <div className='Un3erO1zQlmwBVDJv0ksZSoXg'></div>
                                </div>
                                <div className='hMiNHUqWmcLu0aBV9vnTAIg'>
                                    <input
                                        type="email"
                                        placeholder="Your Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <br />
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <br />
                                </div>

                                <div className='lWVOiK6Zry5uc7ge4CjDUwhptR'>
                                    <div className='La2iSX6glFJAWKtE3uO7fhbmY4pIv' onClick={handleLogin}>
                                        <a className='JQTcxYpazANRCXmSe1B8E3VFvWZ6fU'>
                                            LogIn
                                        </a>
                                    </div>
                                    <div className='dki86woE2Yg4DONLHr3Zuvatc' onClick={() => window.location.href = '/register'}>
                                        <p><a>Register?</a></p>
                                    </div>
                                </div>

                                <div className='IylTGFieUXB2nvpJqtV1WRO5ZKNjQ'>
                                    <div className='douhrsjovuibsgdoifiidjfi'>
                                        <p>Forgot Password? <a href="/requestcode">Get Code</a></p>
                                    </div>
                                </div>
                                {errorMessage && (
                                    <div className="error-message">
                                        {errorMessage}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LogIn;
