import Bottomnav from "./botom_nav";
import Nav from "../nav";
import './style/outlet.css';

function Letests(){


    return(
        <>
            <div>
                <Nav />
                <div>
                    <br /><br /><br />
                </div>
                <div className="ourteam-at-kluret">
                    <div>
                        <div className="__updates_headers_99dj4ejf">
                            <div>
                                <h1 className="text-4xl font-bold mb-6">Stay Updated with Kluret's Latest News</h1>
                            </div>
                            <div>
                                <p>Welcome to our Latest Updates page! Here, you'll find the most recent developments, new features, and enhancements we've made to improve your product search experience with Kluret. Stay tuned as we continuously roll out exciting updates to bring you a more personalized, efficient, and powerful way to find products online in Sweden. Check back often to see what's new!</p>
                            </div>
                        </div>
                    </div>
                    <div className="___intelect">
                        <div className="policy_nest_3004ij0kr">
                            <div className="__policy_diiv">
                                <div>
                                    <h1>AI Chat Features</h1>
                                    <p>The AI chat functionality has been upgraded to provide even more personalized product recommendations. Users can now interact with the AI in a more conversational and intuitive way, getting quicker, more accurate suggestions for the items they're seeking.</p>
                                </div>
                            </div>
                            <div className="__policy_diiv">
                                <div>
                                    <h1>Product Comparison Tools</h1>
                                    <p>Kluret has introduced an enhanced product comparison tool within the product listings. Now, users can more easily compare specifications, prices, and reviews side-by-side, streamlining the decision-making process and helping them find the best deals.</p>
                                </div>
                            </div>
                            <div className="__policy_diiv">
                                <div>
                                    <h1>Product Assist</h1>
                                    <p>Our Product Assist feature has been further developed, allowing users to ask even more detailed questions about products before purchasing. With this update, Kluret now provides deeper insights into product functionality, quality, and alternatives, enhancing user confidence when shopping online.</p>
                                </div>
                            </div>
                            <div className="__policy_diiv">
                                <div>
                                    <h1>Introducing Kluret V1</h1>
                                    <p>We’re excited to announce the launch of Kluret V1, our first major release! This version marks a significant milestone in our journey to revolutionize product search in Sweden. Kluret V1 is an AI-powered search engine that helps users find any product they are looking for, offering a personalized and efficient experience. The AI has been specially trained on a wide range of Swedish products, ensuring fast and relevant results.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="__bottom__399fr9j">
                    <Bottomnav />
                </div>
            </div>
        </>
    )
}


export default Letests;