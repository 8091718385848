import React, { useEffect, useState } from 'react';
import '../styling/myshippiings.css';
import nodelivery_svg from '../styling/imges/nodelivery.svg'

function Myshippiings({ onClose }) {

    return (
        <>
            <div className='myshippiings-componate-49d'>
                <div className='myshippiings-componate-49d-399'>
                    <div>
                        <div>
                            <div className='oops-nothing-inprogress'>
                                <img src={nodelivery_svg} width="100px" alt="" />
                            </div>
                            <p>Oops nothing in progress right now</p>
                            <a href="/chat">
                                <button className='start_shopping-49403'>Start Shopping</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Myshippiings;
