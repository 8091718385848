import React, { useState, useEffect, useCallback, useRef } from 'react';
import './styling/letschat.css';
import usbai_svg from '../tamplates/images/usb_89378.svg';
import close_svg from '../tamplates/images/close.svg';
import refresh_svg from '../tamplates/images/refresh.svg';
import logo_svg from './__whitepaper__/images/kluret_wt.svg';
import kluret_svgchat from '../tamplates/images/kluret_svgchat.svg';
import send_svg from '../tamplates/images/send.svg';
import like from '../tamplates/images/like.svg';
import right_logo_svg from '../tamplates/images/right_logo.svg';
import full_screen_svg from '../tamplates/images/full_screen.svg';
import shipping_svg from '../tamplates/images/shipping.svg';
import settings_svg from '../tamplates/images/settings.svg';
import product_shipping_svg from '../tamplates/images/product_shipping.svg';
import logout_svg from '../tamplates/images/logout.svg';
import latest_svg from '../tamplates/images/latest.svg';
import filter_svg from '../tamplates/images/filter.svg';
import ai_fintune_svg from '../tamplates/images/ai_fintune.svg';
import ProductPage from './__main__/productpage';
import CartKluret from './__main__/cart';
import ProfileKluret from './__main__/profile';
import TrakingKluret from './__main__/traking';
import { useNavigate } from 'react-router-dom';
import Usermenu from './__main__/usermenu';
import './styling/searchengine.css';
import FAQ from './__main__/aboutkluret';
import loove_svg from '../tamplates/images/loove.svg';
import './styling/product_card.css';
import searchengine_svg from '../tamplates/images/searchengine.svg';
import cart_svgg from './styling/imges/cart_img.svg';
import profile_svgg from './styling/imges/profile.svg';
import SearchEgine from './__main__/searchengine';
import AuthPage from '../auth/auth';
import image_recognition_svg from './styling/imges/image_recognition.svg'
import gotohome_svg from './__main__/whitepaper_img/other_white.svg'

const generateRandomClassName = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

// Check if user is logged in
const isUserLoggedIn = () => {
    return localStorage.getItem('sessionToken') !== null;
};

// Show full-screen login if not logged in (Updated to pop up AuthPage instead of alert)
const showFullScreenAuth = (setShowAuth) => {
    setShowAuth(true);  // Set state to show AuthPage component
};

function Letschat() {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [productListingsActive, setProductListingsActive] = useState(false);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    const [slowConnection, setSlowConnection] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [cartVisible, setCartVisible] = useState(false);
    const [profileVisible, setProfileVisible] = useState(false);
    const [trackingVisible, setTrackingVisible] = useState(false); // New state for tracking visibility
    const [searchQuery, setSearchQuery] = useState(''); // New state for search query
    const [fetching, setFetching] = useState(false); // State for fetching status
    const [error, setError] = useState(null); // State for errors
    const [pageIndex, setPageIndex] = useState(1); // Initialize the page state to 1
    const [successfulRequests, setSuccessfulRequests] = useState(0);
    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);
    const [additionalData, setAdditionalData] = useState({}); // State for additional data from response
    const [buttonVisible, setButtonVisible] = useState(false); // State for button visibility
    const [isTyping, setIsTyping] = useState(false); // State for typing animation
    const [stopTyping, setStopTyping] = useState(false); // State to stop typing
    const [showAuth, setShowAuth] = useState(false); // New state to control auth component visibility
    const authContentRef = useRef(null); // New ref for the auth content
    const textareaRef = useRef(null);
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(true);
    const [__isVisible__, __setIsVisible__] = useState(true); // Default to true
    const observer = useRef();
    const searchEngineRef = useRef(null);
    const profileRef = useRef(null); // New ref for profile content
    const [uploadedImage, setUploadedImage] = useState(null);
    const [showFullScreen, setShowFullScreen] = useState(false);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setUploadedImage(reader.result); // Save the uploaded image
            };
            reader.readAsDataURL(file);
        }
    };
    
    const handlePasteEvent = (event) => {
        const clipboardItems = event.clipboardData.items;
    
        // Iterate through clipboard items to find an image
        for (const item of clipboardItems) {
            if (item.type.startsWith("image/")) {
                const file = item.getAsFile(); // Get the file from the clipboard
                if (file) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        setUploadedImage(reader.result); // Save the pasted image
                    };
                    reader.readAsDataURL(file);
                }
                event.preventDefault(); // Prevent default paste behavior
                break;
            }
        }
    };
    
    // Add event listener for paste functionality
    useEffect(() => {
        document.addEventListener("paste", handlePasteEvent);
    
        // Cleanup on component unmount
        return () => {
            document.removeEventListener("paste", handlePasteEvent);
        };
    }, []);
    
    const simulateImageUpload = (imageUrl) => {
        // Find the input element
        const inputElement = document.getElementById("image-upload");
    
        if (!inputElement) {
            console.error("Image upload input element not found");
            return;
        }
    
        // Fetch the image from the given URL and convert it into a File object
        fetch(imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const fileName = "simulated_image.jpg"; // Name for the simulated file
                const simulatedFile = new File([blob], fileName, { type: blob.type });
    
                // Create a DataTransfer object to simulate a user drag-and-drop or selection
                const dataTransfer = new DataTransfer();
                dataTransfer.items.add(simulatedFile);
    
                // Assign the DataTransfer object to the input element
                inputElement.files = dataTransfer.files;
    
                // Trigger the `change` event to mimic user interaction
                const event = new Event("change", { bubbles: true });
                inputElement.dispatchEvent(event);
            })
            .catch((error) => {
                console.error("Error simulating image upload:", error);
            });
    };
    

    const handleRemoveImage = () => {
    setUploadedImage(null); // Remove the uploaded image
    setShowFullScreen(false); // Close full-screen if open
    };




    // Auto-check if user is logged in every second and hide auth page if they are logged in
    useEffect(() => {
        const checkLoginStatus = setInterval(() => {
            if (isUserLoggedIn()) {
                setShowAuth(false); // Hide auth page if user is logged in
            }
        }, 1000); // Check every second

        return () => clearInterval(checkLoginStatus); // Cleanup interval on unmount
    }, []);

    useEffect(() => {
        // Add event listener for hover effect
        const handleMouseOver = () => {
            if (searchEngineRef.current) {
                searchEngineRef.current.classList.add('persistent');
            }
        };

        const searchEngineElement = searchEngineRef.current;
        if (searchEngineElement) {
            searchEngineElement.addEventListener('mouseover', handleMouseOver);
        }

        // Cleanup event listener on component unmount
        return () => {
            if (searchEngineElement) {
                searchEngineElement.removeEventListener('mouseover', handleMouseOver);
            }
        };
    }, []);

    // Toggle function
    function handleToggle() {
        setIsVisible(prev => !prev);
    }

    // useEffect to synchronize visibility states
    useEffect(() => {
        if (isVisible && __isVisible__) {
            __setIsVisible__(false);
        } else if (!isVisible && !__isVisible__) {
            __setIsVisible__(true);
        }
    }, [isVisible, __isVisible__]);

    const fetchProducts = useCallback(async (productName, pageIndex, retries = 3, delay = 1000) => {
        setSlowConnection(false);
        setLoading(true);
        const slowConnectionTimeout = setTimeout(() => {
            setSlowConnection(true);
        }, 5000); // 5 seconds timeout for slow connection

        try {
            console.log(`Requesting product: ${productName} on page ${pageIndex}`);
            const response = await fetch('https://webnode-9662dc9a689b.herokuapp.com/Electronics/product/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ product_name: productName, page_index: pageIndex }),
            });

            clearTimeout(slowConnectionTimeout); // Clear timeout if response is received
            setSlowConnection(false); // Reset slow connection state

            if (!response.ok) {
                throw new Error(`Failed to fetch products from ${response.url}`);
            }

            const data = await response.json();
            console.log(data);
            const productsList = data.products.map((product) => ({
                id: product.link.split('/').pop(),
                name: product.name,
                price: product.price,
                color: product.color,
                product_size: product.sizes,
                product_page: product.link,
                description: product.description,
                images: [product.image],
                cover_image: product.image || 'placeholder_image_url',
            }));

            setProducts(prevProducts => [...prevProducts, ...productsList]);
            setLoading(false);
            setSuccessfulRequests(prev => prev + 1);
        } catch (error) {
            if (retries > 0) {
                console.log(`Retrying... (${retries} attempts left)`);
                setTimeout(() => fetchProducts(productName, pageIndex, retries - 1, delay * 2), delay);
            } else {
                console.error('Error fetching products:', error);
                setError(error);
                setLoading(false);
                setSuccessfulRequests(0); // Reset successful requests count if retry limit is reached
                console.log(`Skipping page ${pageIndex + 1}`);
                setPageIndex(prev => prev + 1);
            }
        }
    }, []);

    useEffect(() => {
        if (successfulRequests > 0 && successfulRequests % 2 === 0) {
            console.log(`Requesting next index: ${pageIndex + 1}`);
            setPageIndex(prev => prev + 1);
        }
    }, [successfulRequests]);

    useEffect(() => {
        const filtered = products.filter(product =>
            product.name && product.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

        const productCountMap = {};
        const limitedDuplicates = filtered.filter(product => {
            const name = product.name;
            if (!productCountMap[name]) {
                productCountMap[name] = 0;
            }
            if (productCountMap[name] < 1) {
                productCountMap[name]++;
                return true;
            }
            return false;
        });

        setFilteredProducts(limitedDuplicates);
    }, [searchQuery, products]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Prevent the default behavior (e.g., a new line in textarea)
            handleSendMessage(); // Call the function to handle sending the message
        }
    };

    const extractProductNameFromResponse = (response) => {
        const productNameRegex = /\*\/\/(.*?)\/\/\*/;
        const match = response.match(productNameRegex);
        if (match && match[1]) {
            return match[1].trim();
        }
        return null;
    };

    const extractMessagePart = (response) => {
        const messagePartRegex = /\(\((.*?)\)\)/;
        const match = response.match(messagePartRegex);
        if (match && match[1]) {
            return match[1].trim();
        }
        return null;
    };

    const typeText = (text, callback) => {
        let index = 0;
        const intervalId = setInterval(() => {
            if (index < text.length) {
                callback(text.slice(0, index + 1));
                index += 1;
            } else {
                clearInterval(intervalId);
            }
        }, 5); // Adjust the speed of typing here
    };

    const generateRandomUserId = () => {
        const length = Math.floor(Math.random() * (70 - 30 + 1)) + 30; // Random length between 30 and 70
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let userId = '';
        for (let i = 0; i < length; i++) {
            userId += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return userId;
    };

    const handleSendMessage = async () => {
        if (inputValue.trim() !== "" || uploadedImage) {
            const userMessage = {
                text: inputValue || "Describe this product",
                sender: "user",
                image: uploadedImage, // Include the uploaded image preview
            };
    
            setMessages((prevMessages) => [userMessage, ...prevMessages]);
    
            setInputValue("");
            setUploadedImage(null);
            setLoading(true);
            setIsTyping(true);

            // Add a unique ID to the latest message for scanning
            setTimeout(() => {
                // Remove scanning-active from all previous images
                const uploadedImages = document.querySelectorAll(".uploaded_images_inchat");
                uploadedImages.forEach((image) => {
                    image.classList.remove("scanning-active");
                });

                // Add scanning-active to the latest image
                const lastUploadedImage = uploadedImages[0]; // Target the most recent message (bottom image)
                if (lastUploadedImage) {
                    void lastUploadedImage.offsetWidth; // Trigger a reflow to restart the animation
                    lastUploadedImage.classList.add("scanning-active");
                }
            }, 100); // Delay to ensure the message is rendered
            
            const messageHistory = messages
                .map((message) =>
                    `${message.sender === "user" ? "user_input" : "chatbot_response"}: ${
                        message.text
                    }`
                )
                .join("\n");
    
            const loadingMessagePlaceholder = { text: "", sender: "chatbot", loading: true };
            setMessages((prevMessages) => [loadingMessagePlaceholder, ...prevMessages]);
    
            let userId = localStorage.getItem("user_id");
            if (!userId) {
                userId = generateRandomUserId();
                localStorage.setItem("user_id", userId); // Persist user ID across sessions
            }
    
            try {
                let response, data;
    
                if (uploadedImage) {
                    // Convert base64 or image URL to Blob for upload
                    const blob = await fetch(uploadedImage).then((res) => res.blob());
                    const formData = new FormData();
    
                    // Append data to FormData
                    formData.append("user_input", userMessage.text);
                    formData.append("user_history", messageHistory);
                    formData.append("user_id", userId);
                    formData.append("image", blob, "uploaded_image.jpg"); // Provide a filename for the uploaded image
    
                    // Make the request with FormData
                    response = await fetch(
                        "https://engine1-f36f7fb18f56.herokuapp.com/imagebase_api_ai/chatbot/",
                        {
                            method: "POST",
                            headers: {
                                Authorization:
                                    "WYe24yqdC0EczXBaoT4aCFa-tRrKpC-hFqxiiqFuaI5cN75dU1j4KvIxB0yCZLOy5SY",
                            },
                            body: formData, // Use FormData for multipart data
                        }
                    );
                } else {
                    // Text-only request
                    response = await fetch(
                        "https://engine1-f36f7fb18f56.herokuapp.com/textbase_api/chatbot/",
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization:
                                    "WYe24yqdC0EczXBaoT4aCFa-tRrKpC-hFqxiiqFuaI5cN75dU1j4KvIxB0yCZLOy5SY",
                            },
                            body: JSON.stringify({
                                user_input: inputValue,
                                user_history: messageHistory,
                                user_id: userId,
                            }),
                        }
                    );
                }
    
                if (!response.ok) {
                    console.error(`Server Error: ${response.status}`);
                    throw new Error("Network response was not ok.");
                }
    
                data = await response.json();
                let chatbotResponse = data.response;
                setAdditionalData(data.additional_data); // Save additional data
                console.log("Additional data:", data.additional_data);
                console.log("Bot response:", chatbotResponse);
    
                const isBuying = chatbotResponse.includes("<the_user_is_buying_>");
    
                if (isBuying) {
                    setLoading(true);
    
                    let productName = extractProductNameFromResponse(chatbotResponse);
                    if (productName) {
                        setPageIndex(1);
                        for (let i = 0; i < 2; i++) {
                            await fetchProducts(productName, pageIndex);
                            await fetchProducts(productName, pageIndex + 1);
                            await new Promise((resolve) => setTimeout(resolve, 2000));
                        }
    
                        setTimeout(() => {
                            setLoading(false);
    
                            const filtered = products.filter(
                                (product) =>
                                    product.name &&
                                    product.name
                                        .toLowerCase()
                                        .includes(productName.toLowerCase())
                            );
    
                            const productCountMap = {};
                            const limitedDuplicates = filtered.filter((product) => {
                                const name = product.name;
                                if (!productCountMap[name]) {
                                    productCountMap[name] = 0;
                                }
                                if (productCountMap[name] < 2) {
                                    productCountMap[name]++;
                                    return true;
                                }
                                return false;
                            });
    
                            setFilteredProducts(limitedDuplicates);
                            setProductListingsActive(true);
                            setSearchQuery(productName);
                        }, 3000);
                    }
                } else {
                    const chatbotMessage = { text: "", sender: "chatbot" };
    
                    setLoading(false);
    
                    typeText(chatbotResponse, (typedText) => {
                        setMessages((prevMessages) => {
                            const updatedMessages = [...prevMessages];
                            updatedMessages[0] = {
                                text: typedText,
                                sender: "chatbot",
                                loading: false,
                            };
    
                            if (data.additional_data?.open) {
                                updatedMessages[0].viewButton = (
                                    <button
                                        onClick={() => {
                                            if (!isUserLoggedIn()) {
                                                showFullScreenAuth(setShowAuth);
                                            } else {
                                                handleSearchIconClick();
                                                if (data.additional_data.product) {
                                                    localStorage.setItem(
                                                        "productName",
                                                        data.additional_data.product
                                                    );
                                                }
                                                if (data.additional_data.price) {
                                                    localStorage.setItem(
                                                        "productPrice",
                                                        data.additional_data.price
                                                    );
                                                }
    
                                                setTimeout(() => {
                                                    localStorage.removeItem("productName");
                                                }, 3000);
                                            }
                                        }}
                                        className="jiphoadugfuisydfkuwipsodifuwsdohifkwks____view"
                                    >
                                        View
                                    </button>
                                );
                            }
    
                            return updatedMessages;
                        });
                    });
    
                    if (data.additional_data?.product) {
                        setSearchQuery(data.additional_data.product);
                    }
                }
            } catch (error) {
                console.error("Error:", error.message);
                setMessages((prevMessages) => [
                    {
                        text: "There was an error with our server. Please try again.",
                        sender: "error",
                    },
                    ...prevMessages,
                ]);
            } finally {
                setLoading(false);
                setIsTyping(false);
            }
        }
    };
    
    
    
    

    const handleToggleProductListings = async (productName) => {
        if (menuVisible) {
            setMenuVisible(false);
        }

        setProductListingsActive(prevState => !prevState);
        if (productName) {
            setSearchQuery(productName);
            await fetchProducts(productName, pageIndex);
        }
        if (productListingsActive) {
            setSelectedProduct(null);
        }

        setIsVisible(!isVisible);
    };

    const handleToggleMenu = () => {
        if (productListingsActive) {
            setProductListingsActive(false);
            setMenuVisible(prevState => !prevState);
        } else {
            setMenuVisible(prevState => !prevState);
        }
    };

    const handleProductClick = (product) => {
        setSelectedProduct(product);
        startFetchingProducts(product.name);
    };

    const handleCloseProductPage = () => {
        setSelectedProduct(null);
    };

    const handleLogout = () => {
        localStorage.removeItem('sessionToken');
        navigate('/login');
    };

    const handleToggleCart = () => {
        setCartVisible(true);
        setProfileVisible(false);
        setTrackingVisible(false);
    };

    const handleToggleProfile = () => {
        setProfileVisible(true);
        setCartVisible(false);
        setTrackingVisible(false);
    };

    const handleToggleTracking = () => {
        setTrackingVisible(prevState => !prevState);
        setCartVisible(false);
        setProfileVisible(false);
    };

    const handleProductSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    };

    const handleProductSearchClick = () => {
        fetchProducts(searchQuery, pageIndex);
    };

    const extractPrice = (priceString) => {
        if (!priceString) {
            return null; // Return null if price is not available
        }

        const numericString = priceString.replace(/[^0-9,]/g, '');
        const standardizedString = numericString.replace(',', '.');
        const numericPrice = parseFloat(standardizedString);

        if (isNaN(numericPrice)) {
            return '0.00 kr';
        }

        const priceInCents = Math.round(numericPrice * 100);
        const formattedPrice = (priceInCents / 100).toFixed(2);

        return `${formattedPrice} kr`;
    };

    const truncateDescription = (description) => {
        if (!description) return '';
        const words = description.split(' ');
        if (words.length <= 0) return description;
        return words.slice(0, 0).join(' ') + '';
    };

    const handleLoadMore = async () => {
        const loadingMessages = ['Loading.', 'Loading..', 'Loading...'];
        let messageIndex = 0;
        const intervalId = setInterval(() => {
            setLoadingMessage(loadingMessages[messageIndex]);
            messageIndex = (messageIndex + 1) % loadingMessages.length;
        }, 500); // 500ms interval for message change

        setFetching(true);

        try {
            const nextPage = pageIndex + 1;
            await fetchProducts(searchQuery, nextPage);
            setPageIndex(nextPage);
        } catch (error) {
            console.error('Error:', error);
            setError(error);
        } finally {
            clearInterval(intervalId);
            setLoadingMessage('Loading...');
            setFetching(false);
        }
    };

    const ProductRow = ({ index }) => {
        const product = filteredProducts[index];
        const randomClassName = generateRandomClassName(Math.floor(Math.random() * (40 - 20 + 1)) + 20);

        useEffect(() => {
            const imgElement = document.getElementById(`product-image-${index}`);
            if (imgElement) {
                imgElement.src = product.cover_image;
            }
        }, [product.cover_image, index]);

        return (
            <div
                key={product.id}
                className={`ngouyufcgivouhipjohguyfmx-auto mt-11 transform overflow-hidden rounded-lg bg-white dark:bg-slate-800 duration-300 hover:scale-105 hover:shadow-lg ${randomClassName}`}
                onClick={() => handleProductClick(product)}
                id='jdhy9548thujdihbeukoihugyf8t8g9'
            >
                <img id={`product-image-${index}`} className="h-48 w-full object-cover object-center" src="https://kluret.se/images/placeholder.jpg" alt={product.name} />
                <div className="p-0">
                    <h2 className="mb-2 text-lg font-medium dark:text-white text-gray-900">{product.name}</h2>
                    <div className="" id='oihugyftdut7897r68dtfyuhiug9y'>
                        <p className="mr-2 text-lg font-semibold text-gray-900 dark:text-white">{extractPrice(product.price)}</p>
                        <img className='oihuy8ftd7rf87f657ryuyg97t' src={loove_svg} alt="" />
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        const element = document.querySelector('.Gpx95DVP4hnZlwMRdz6gWFNc7u');
        if (element) {
            if (window.innerWidth < 1100) {
                element.style.display = 'none';
            } else {
                element.style.display = 'block';
            }
        }
    }, []);

    useEffect(() => {
        const element = document.querySelector('.Gpx95DVP4hnZlwMRdz6gWFNc7u');
        if (element) {
            if (window.innerWidth < 1100) {
                element.style.display = 'none';
            } else {
                element.style.display = 'block';
            }
        }
    }, []);

    const handleSvgClick = () => {
        const element1 = document.querySelector('.Gpx95DVP4hnZlwMRdz6gWFNc7u');
        const element2 = document.querySelector('.vY8JltTPkOZ2pc9oF6mhu5bKaqSw');

        if (element1 && element2) {
            element1.style.display = 'block';
            setTimeout(() => { // Adding a timeout to ensure the transition is applied
                element1.style.width = '100%';
            }, 10);
            element2.style.width = '0%';
            setTimeout(() => { // Adding a timeout to hide the element after transition
                element2.style.display = 'none';
            }, 500);
        }
    };

    const handleSvgClick_2 = () => {
        const element1 = document.querySelector('.Gpx95DVP4hnZlwMRdz6gWFNc7u');
        const element2 = document.querySelector('.vY8JltTPkOZ2pc9oF6mhu5bKaqSw');

        if (element1 && element2) {
            if (window.innerWidth > 1100) {
                element1.style.width = '25%';
                setTimeout(() => { // Adding a timeout to ensure the transition is applied
                    element2.style.width = '75%';
                    element2.style.display = 'block';
                }, 10);
            } else {
                element1.style.width = '0%';
                setTimeout(() => { // Adding a timeout to hide the element after transition
                    element1.style.display = 'none';
                    element2.style.width = '100%';
                    element2.style.display = 'block';
                }, 500);
            }
        }
    };

    const handleSearchIconClick = () => {
        const searchEngineDiv = document.querySelector('.ohivgucguih0uugyfiohihugi_searchengine');

        if (searchEngineDiv) {
            if (searchEngineDiv.classList.contains('active')) {
                searchEngineDiv.classList.remove('active');
                setTimeout(() => {
                    searchEngineDiv.style.display = 'none'; // Hide the element after the transition
                }, 500); // Match the transition duration
            } else {
                searchEngineDiv.style.display = 'block'; // Ensure the element is displayed before the transition
                setTimeout(() => {
                    searchEngineDiv.classList.add('active');
                }, 10); // Slight delay to ensure the class is added after display is set to block
            }
        }
    };

    const handleSearchClose = () => {
        const searchEngineDiv = document.querySelector('.ohivgucguih0uugyfiohihugi_searchengine');

        if (searchEngineDiv) {
            searchEngineDiv.classList.remove('active');
            setTimeout(() => {
                searchEngineDiv.style.display = 'none';
            }, 500); // Match the transition duration
        }
    };

    const startFetchingProducts = (productName) => {
        let currentIndex = 1;
        const intervalId = setInterval(async () => {
            console.log(`Requesting next index: ${currentIndex}`);
            await fetchProducts(productName, currentIndex);
            currentIndex += 1;
        }, 1000);

        return () => clearInterval(intervalId);
    };

    const handleSvgProfileClick = () => {
        setProfileVisible(true);
    };

    const handleCloseProfile = () => {
        setProfileVisible(false);
    };

    const handleCloseAuth = () => {
        setShowAuth(false); // Close the AuthPage popup
    };

    // New effect to hide AuthPage when clicking outside of the auth-content only
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (authContentRef.current && !authContentRef.current.contains(event.target)) {
                setShowAuth(false); // Hide auth popup if clicked outside auth content
            }
        };

        if (showAuth) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside); // Cleanup
        };
    }, [showAuth]);

    // New effect to hide profile when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (profileRef.current && !profileRef.current.contains(event.target)) {
                setProfileVisible(false); // Hide profile if clicked outside
            }
        };

        if (profileVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside); // Cleanup
        };
    }, [profileVisible]);

    return (
        <div className='welcometothechat'>
            <style>{`
                .ohivgucguih0uugyfiohihugi_searchengine {
                    display: none;
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 0vh;
                    background-color: black;
                    align-items: center;
                    justify-content: center;
                    padding: 20px 0;
                    transition: height 0.5s ease-in-out, padding 0.5s ease-in-out; /* Adding transition for height and padding */
                    z-index: 1000;
                    backdrop-filter: blur(53px); /* Corrected the backdrop-filter property */
                }
                .ohivgucguih0uugyfiohihugi_searchengine.active {
                    display: flex;
                    height: 100vh;
                    justify-content: center;
                    align-items: center;
                    padding: 0px 0;
                }
                .ohivgucguih0uugyfiohihugi_searchengine.persistent {
                    padding: 20px 0;
                }

                .userprofile_98749ejr4h8r849h8r420ijf9 {
                    height: ${profileVisible ? 'auto' : '0vh'};
                    overflow: hidden;
                    transition: height 0.5s ease-in-out;
                }
                .typing-dots {
                    display: flex;
                    gap: 5px;
                    margin-top: 5px;
                }
                .typing-dots span {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: #ccc;
                    animation: typing-dot-blink 1.2s infinite both;
                }
                @keyframes typing-dot-blink {
                    0% { opacity: 0.2; }
                    20% { opacity: 1; }
                    100% { opacity: 0.2; }
                }
                .typing-dots span:nth-child(1) {
                    animation-delay: 0s;
                }
                .typing-dots span:nth-child(2) {
                    animation-delay: 0.2s;
                }
                .typing-dots span:nth-child(3) {
                    animation-delay: 0.4s;
                }
                .error-message {
                    color: red;
                    margin-top: 5px;
                }
                .tY5Xjc1JHoCr9izRuxvFeDSOP2nkZ .npG6QFDIwNjoWuSJ9l0edX .VMXKvbSn1GtUekYy6WsH8c4axDr{
                    background-color: rgba(246, 246, 246, 0);
                }

                .auth-container {
                    position: fixed;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 9999;
                    width: 100vw;
                    height: 100vh;
                    top: 0;
                    left: 0;
                    margin-left:20px;
                    background-color: rgba(211, 203, 203, 0); /* Transparent background */
                }
                .auth-content {
                    background-color: rgba(211, 203, 203, 0);
                    padding: 30px;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: fit-content;
                    height: fit-content;
                    position: relative;
                }
            `}</style>
            <div className='Gpx95DVP4hnZlwMRdz6gWFNc7u'>
                <div className='iodhgrih948r998g3r'>
                    <div className='oijuhfw3rer4rlkhgowrgur'>
                        <h1>Kluret AI</h1>

                        <div className='__holder_0ri49ure_wd9je'>
                            <a href="/" target='_black'>
                                <div className='__gotohome__9ehfrfde8f9rfhr'>
                                    <img src={gotohome_svg} alt="" />
                                </div>
                            </a>
                            <span onClick={handleSvgClick_2} className='ojihugy89h0hugy99fuh0iojbduh0o'>
                                <img src={close_svg} alt="" />
                            </span>
                        </div>
                    </div>
                    <div className='ihwukdgkjoehug9etufjg'>
                        <FAQ />
                    </div>
                </div>
            </div>
            <div className='vY8JltTPkOZ2pc9oF6mhu5bKaqSw'>
                <div className="TNUc1begjEMZvd6q8tY3">
                    <div className='RayFuOXS6nIV2MZotHkNPUqwgc348'>
                        <div className={`product_listings_84902 ${productListingsActive ? 'product_listings_active' : ''}`}>
                            <div className="productcart_123">
                                <div className='dropdown_category'>
                                    <div className='ooiuirhoghwrriruir'>
                                        <div className="x0qJULzVOZtES2Co5MKT63mIY">
                                            <div className="WSCp9YhcsyfqMXIzDb1uOkVlaRv">
                                                <div className="DbtfUCInZ8rM3i9AzV0dPhy" id='DbtfUCInZ8rM3i9AzV0dPhy' style={{ display: isVisible ? 'block' : 'none' }} onClick={handleSvgClick}>
                                                    <img src={logo_svg} width="40px" alt="" />
                                                </div>
                                            </div>
                                            <div className="Ts0lHxnBGq7ij5L1k8b9QCf" style={{ display: isVisible ? 'block' : 'none' }}>
                                                <div className='jiivhugcugui9gy8uiefib8ydigjbf8rijkg'>
                                                    <div className='iwursgduioeporuhsfgoetuigojkedfjkmgruieojg___gpeul'>
                                                    <div onClick={handleSearchIconClick}>
                                                        <img src={searchengine_svg} width="22px" alt="" />
                                                    </div>

                                                    <div className='iohugyfhowjpdkofjihuwoj'>
                                                        <svg
                                                            width="30px"
                                                            height="30px"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            onClick={handleSvgProfileClick}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                            <g id="SVGRepo_iconCarrier">
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M16.0724 4.02447C15.1063 3.04182 13.7429 2.5 12.152 2.5C10.5611 2.5 9.19773 3.04182 8.23167 4.02447C7.26636 5.00636 6.73644 6.38891 6.73644 8C6.73644 10.169 7.68081 11.567 8.8496 12.4062C9.07675 12.5692 9.3115 12.7107 9.54832 12.8327C8.24215 13.1916 7.18158 13.8173 6.31809 14.5934C4.95272 15.8205 4.10647 17.3993 3.53633 18.813C3.43305 19.0691 3.55693 19.3604 3.81304 19.4637C4.06914 19.567 4.36047 19.4431 4.46375 19.187C5.00642 17.8414 5.78146 16.4202 6.98653 15.3371C8.1795 14.265 9.82009 13.5 12.152 13.5C14.332 13.5 15.9058 14.1685 17.074 15.1279C18.252 16.0953 19.0453 17.3816 19.6137 18.6532C19.9929 19.5016 19.3274 20.5 18.2827 20.5H6.74488C6.46874 20.5 6.24488 20.7239 6.24488 21C6.24488 21.2761 6.46874 21.5 6.74488 21.5H18.2827C19.9348 21.5 21.2479 19.8588 20.5267 18.2452C19.9232 16.8952 19.0504 15.4569 17.7087 14.3551C16.9123 13.7011 15.9603 13.1737 14.8203 12.8507C15.43 12.5136 15.9312 12.0662 16.33 11.5591C17.1929 10.462 17.5676 9.10016 17.5676 8C17.5676 6.38891 17.0377 5.00636 16.0724 4.02447ZM15.3593 4.72553C16.1144 5.49364 16.5676 6.61109 16.5676 8C16.5676 8.89984 16.2541 10.038 15.544 10.9409C14.8475 11.8265 13.7607 12.5 12.152 12.5C11.5014 12.5 10.3789 12.2731 9.43284 11.5938C8.51251 10.933 7.73644 9.83102 7.73644 8C7.73644 6.61109 8.18963 5.49364 8.94477 4.72553C9.69916 3.95818 10.7935 3.5 12.152 3.5C13.5105 3.5 14.6049 3.95818 15.3593 4.72553Z"
                                                                    fill="#000000"
                                                                ></path>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="userprofile_98749ejr4h8r849h8r420ijf9" ref={profileRef}>
                                            {profileVisible && <ProfileKluret onClose={handleCloseProfile} />}
                                        </div>
                                        <div className={`mainmenuholder_284738 ${menuVisible ? 'visible' : ''}`}>
                                            <Usermenu />
                                        </div>
                                        <div className="ohivgucguih0uugyfiohihugi_searchengine" ref={searchEngineRef}>
                                            <div className='ojihugyugus9y8g7yurgh9ry8ehosjg'>
                                                <SearchEgine onClose={handleSearchClose} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`selectedProduct_section ${selectedProduct ? 'visible' : ''}`}>
                                    {selectedProduct && <ProductPage product={selectedProduct} onClose={handleCloseProductPage} />}
                                </div>
                            </div>
                            <div className={`product_center_0988403 ${selectedProduct ? 'hidden' : ''}`}>
                                <div className="vdT4V1ZRXucn8zAtaOy0NgLYEMW" style={{ display: __isVisible__ ? 'block' : 'none' }}>
                                    <div className='jojodiguerojssgjuf'>
                                        <div className='jihugyftyguhihguwgfgigrwiggfj'>
                                            <p>You are looking for '{searchQuery}'</p>
                                        </div>
                                        <div className='oioubfjgdogrpmf39jfid'>
                                            <p>Over {filteredProducts.length}+</p>
                                        </div>
                                    </div>
                                </div>
                            <div className="product_listings_scrollable">


                                {filteredProducts.length > 0 ? (
                                    filteredProducts.map((product, index) => (
                                        <ProductRow key={index} index={index} />
                                    ))
                                ) : (
                                    <div className="loading_animation">
                                        {slowConnection && (
                                            <div class="spinner border-4 border-blue-500 border-solid rounded-full w-12 h-12"></div>
                                        )}
                                    </div>
                                )}
                                <style jsx>{`
                                    .product-list-container {
                                        display: flex;
                                        flex-wrap: wrap;
                                        gap: 20px;
                                        justify-content: center;
                                    }
                                    .product-list-container .product-item {
                                        flex: 1 1 20%;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        background-color: #FFFFFF;
                                        border-radius: 5px;
                                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                                        transition: transform 0.2s, box-shadow 0.2s;
                                        padding: 10px;
                                    }
                                    .product-list-container .product-item:hover {
                                        transform: scale(1.05);
                                        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
                                    }
                                    .product-list-container .product-item a {
                                        text-decoration: none;
                                        color: inherit;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        width: 100%;
                                    }
                                    .product-list-container .product-item-image-container {
                                        width: 100%;
                                        height: auto;
                                        padding-bottom: 10px;
                                    }
                                    .product-list-container .product-item-image-container img {
                                        width: 100%;
                                        border-radius: 5px 5px 0 0;
                                    }
                                    .product-list-container .product-item-details {
                                        width: 100%;
                                        text-align: center;
                                    }
                                    .product-list-container .description-wrapper {
                                        padding: 10px 0;
                                    }
                                    .product-list-container .description {
                                        font-family: "Jura", sans-serif;
                                        font-weight: 400;
                                        font-size: 12px;
                                        line-height: 15px;
                                        color: #000000;
                                    }
                                    .product-list-container .price-wrapper {
                                        padding: 5px 0;
                                    }
                                    .product-list-container .price {
                                        font-family: "Jura", sans-serif;
                                        font-weight: 400;
                                        font-size: 10px;
                                        line-height: 12px;
                                        color: #101010;
                                    }
                                    .product-list-container .add-to-cart {
                                        position: relative;
                                        width: 100%;
                                        background: linear-gradient(90deg, #0029FF 0%, #000000 60%, #000AFF 100%);
                                        border-radius: 30px;
                                        text-align: center;
                                        padding: 5px 0;
                                        margin-top: 10px;
                                        cursor: pointer;
                                    }
                                    .product-list-container .add-to-cart span {
                                        font-family: "Jura", sans-serif;
                                        font-weight: 400;
                                        font-size: 7px;
                                        line-height: 8px;
                                        color: #FFFFFF;
                                    }
                                    .product-list-container .load-more-trigger {
                                        width: 100%;
                                        height: 1px;
                                        background: transparent;
                                    }

                                    /* Responsive styles */
                                    @media (max-width: 700px) {
                                        .product-list-container .product-item {
                                            flex: 1 1 40%;
                                        }
                                    }
                                `}</style>
                            </div>

                                <div className='loadmorecontainer_lojiyuvcy'>
                                    <div className='opjihogyftyggu978ifbjkdoh9'>
                                        <div class="spinner border-4 border-blue-500 border-solid rounded-full w-7 h-7"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`QY736rhACmdyoMlGp4nUqwF2W ${selectedProduct ? 'block' : 'flex'}`} onToggleProductListings={handleToggleProductListings}>
                            <div className='iQkVRcLWIUGmCajfTuPSh9'></div>
                            <div className='t76a4IyK3dTgGm5vAPlrORYkNjq'>
                                <div className='VIiKsZOxGW0AnRg3Byljo1wk59'>
                                    <div className='itKlJ8azFqP6E9wC0hR2YWujD1snMm' onClick={() => handleToggleProductListings(null)}>
                                        <img src={close_svg} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='chatcenter'>
                    {messages.length === 0 ? (
                        <div className="displaymessage_o8h4r_recomands_quesstion">
                            <div className='iouwfhkgjbgruie'>
                                <div className='ojwdsdiuohgohrrjgie_1'>
                                    <div className='wjsiugerui__1'>
                                        <div>
                                            <img src={kluret_svgchat} alt="" />
                                        </div>
                                    </div>
                                    <div className='ojiodhgor0he309rwos'>
                                        <div className='poihordgigwhi'>
                                            <div className='pijogrshdugohruog3j'>
                                                <p>Kluret AI</p>
                                                <div className='___divdisplay___39jr4e9fj'>
                                                    <m className='___viarkluret___'>Med Kluret samlar vi alla dina produkter på ett enda ställe för bättre överblick och enkel hantering!</m>
                                                    <m className='___viarkluret___'>Över 10+ miljoner produkter har vi samlat på ett ställe med Kluret för enkel åtkomst!</m>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {messages.map((message, index) => {
                                const messagePart = extractMessagePart(message.text);
                                const text = messagePart ? message.text.replace(`(( ${messagePart} ))`, '') : message.text;

                                return (
                                    <div key={index + 1} className={message.sender === 'user' ? 'user_input' : 'chatbot_response'} style={{ maxWidth: '100%' }}>
                                    {/* Render image for user messages if an image is present */}
                                    {message.sender === 'user' && message.image && (
                                        <div className="uploaded_images_inchat">
                                            <img
                                                src={message.image}
                                                alt="User uploaded"
                                                style={{ width: '50%', height: '50%', marginBottom: '5px', borderRadius: '5px' }}
                                            />
                                            <div className="scanning-line"></div> {/* Scanning line */}
                                        </div>
                                    )}




                                    {message.sender === 'chatbot' && message.loading ? (
                                        <div className="typing-dots">
                                            <span></span><span></span><span></span>
                                        </div>
                                    ) : message.sender === 'chatbot' && !message.loading ? (
                                        <>
                                            <div className='bot-response-3020'>
                                                <img src={logo_svg} width="20px" alt="" />
                                                <div className='response-test-button-view-39499'>
                                                    <div dangerouslySetInnerHTML={{ __html: text }}></div>
                                                    {message.viewButton && message.viewButton}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <p>{text}</p>
                                    )}
                                    {message.sender === 'error' && (
                                        <div className="error-message">
                                            {text} <button onClick={handleSendMessage}>Retry</button>
                                        </div>
                                    )}
                                </div>

                                );
                            })}
                        </>
                    )}
                </div>
                <div className="ibcveF4k6SmLCqw0TPtGyW1">
                    <div className="Jb0GKzyrjm2e5MDYwqIOnV">
                        <div className="SCxIJi7caEh0UrYsT2vqbONZ relative">
                        {/* Image Processing (Upload Icon or Uploaded Image) */}
                        <div className="image-procssing top-[-50px] left-0">
                            <input
                            type="file"
                            accept="image/*"
                            id="image-upload"
                            className="hidden"
                            onChange={(e) => handleImageUpload(e)}
                            />
                            {!uploadedImage && (
                            <label htmlFor="image-upload" className="cursor-pointer">
                                <img
                                src={image_recognition_svg}
                                alt="Upload"
                                className="w-9 h-9"
                                />
                            </label>
                            )}
                            {uploadedImage && (
                            <div className="relative">
                                <img
                                src={uploadedImage}
                                alt="Uploaded Preview"
                                className="uploaded_image_4930 w-10 h-10 rounded-full border border-gray-300 object-cover"
                                onClick={() => setShowFullScreen(true)}
                                />
                                <button
                                onClick={handleRemoveImage}
                                className="absolute top-0 right-0 bg-white rounded-full p-1 shadow hover:bg-gray-200"
                                >
                                <img
                                    src="https://www.svgrepo.com/show/522388/close.svg"
                                    alt="Remove"
                                    className="w-2 h-2"
                                />
                                </button>
                            </div>
                            )}
                        </div>

                        {/* Full-Screen Preview */}
                        {showFullScreen && uploadedImage && (
                            <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
                            <div className="relative">
                                <img
                                src={uploadedImage}
                                alt="Full-Screen Preview"
                                className="w-52 h-52 object-contain rounded"
                                />
                                <button
                                className="absolute top-0 right-0 bg-white rounded-full p-2"
                                onClick={() => setShowFullScreen(false)}
                                >
                                <img
                                    src="https://www.svgrepo.com/show/522388/close.svg"
                                    alt="Close Fullscreen"
                                    className="w-6 h-6"
                                />
                                </button>
                            </div>
                            </div>
                        )}

                        {/* Chat Input */}
                        <div className="WHY9xg8Lhotrp3cCvQKG7">
                            <textarea
                            ref={textareaRef}
                            placeholder="chatta med kluret :)"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={handleKeyPress}
                            />
                        </div>

                        {/* Send Button */}
                        <div
                            className="JM8IRKfdWhnkXrZ2SVeyDlszA"
                            onClick={handleSendMessage}
                        >
                            <a type="submit">
                            <p className="ai2gxXYbhc9kRpFDtls7m">→</p>
                            </a>
                        </div>
                        </div>
                    </div>
                </div>


            </div>

            {/* New AuthPage Popup */}
            {showAuth && (
                <div className="auth-container">
                    <div className="auth-content" ref={authContentRef}>
                        <AuthPage />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Letschat;
