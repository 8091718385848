import React from 'react';
import { Typewriter } from 'react-simple-typewriter';
import klureicone from './images/klureicone.svg';
import globe_map from './images/globe_map.svg';
import whatkluret from './images/whatkluret.svg'
import withkluret from './images/withkluret.svg'
import likes_svg from './images/like.svg'
import robot_svg from './images/robot.svg'
import instagram_svg from './images/instagram.svg'
import tiktok_svg from './images/tiktok.svg'
import withoutkluret from './images/withoutkluret.svg'


import sweden_img from './images/sweden.png'
import denmark_img from './images/denmark.png'
import norway_img from './images/norway.png'

import sweden_svg from './images/sweden.svg'
import denmark_svg from './images/denmark.svg'
import norway_svg from './images/norway.svg'




import jakob_svg from './images/jakob.svg'
import iphone_emulator_svg from './images/iphone_emulator.svg'



import './styling/home.css';
import Navout from '../tamplates/nav_out';

function HomeKluret() {
    const words = [
        "I'm Looking for nike shoes",
        "I'm Looking for a House in Stockholm",
        "I'm Looking for a coeat"
    ];

    return (
        <>
            <div>
                <div>
                    <div className='osdufgypiåishugyfohgkars'>
                        <Navout />
                    </div>
                    <div className='BJI5WyfRN7obMUL4v1ZQ3A'>
                        <div className='becALBaGpyI5EuzFMHY0ojVNKqD'>
                            <div className='tid9MAGPBj7Kba3yHLulQxcRC'>
                                <div className='Ij3RgJQ2OUfplNFtXomSecq'>
                                    <div className='Md2O1iTBxbnFhgmCQW8lSp6'>
                                        <h1 className='uyguiohfu0e9yuf9'>Kluret AB</h1>
                                        <div className='ioudyifihw9udifuys'>
                                            <div className='seachengine_03943' id='jisouifyodufi'>
                                                <p>
                                                    <Typewriter
                                                        words={words}
                                                        loop={0}
                                                        cursor
                                                        cursorStyle='_'
                                                        typeSpeed={70}
                                                        deleteSpeed={50}
                                                        delaySpeed={1000}
                                                    />
                                                </p>
                                                <span className='sendicone_0098748930'>→</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='TmhLtloVKOjwkPFbd1yYu'>
                                <div className='p5Jne8qh21TYc6mEOVtxWrIUsKb4'>
                                    <a href="/chat">Try Kluret</a>
                                </div>
                            </div>
                        </div>
                    </div>
{/*                     
                    <div className='T84jcFbawXEhDMdRzug7rm2f'>
                        <div className='WR96QH0o8kCAOxfDVqYIZazpKb435j'>
                            
                            <div className='ihufkgjrs9fuge'>

                            </div>
                            
                            <div className='ioiwusyigdhiuefxh'>
                                <div className='iosuhdgyirhsohfgr'>
                                    <div className='jjhougsiyguhf'>Hi there! i'm Kluret...</div>
                                    <div className='oihsuygifujwri9sgregfc'>→</div>
                                </div>
                            </div>

                            <div className='ojishougiehoidfgihger'>
                                <img src={robot_svg} alt="" />
                            </div>

                            <div className='iwoshgiiotjfsd'>
                                <div className='iwhusighieotdfu'>
                                    <h1>Kluret || Free AI Search Engine</h1>
                                </div>

                                <div className='wuisbghiojehuhr8egif'>
                                    <p>Kluret is an AI-powered search engine focused exclusively on Scandinavia, offering unparalleled access to comprehensive information. Our advanced AI algorithms deliver precise, relevant search results, making it easier to find exactly what you need. Whether you're seeking the latest in Scandinavian design, detailed product reviews, or cultural insights, Kluret ensures valuable results.
                                    <br /><br />
                                    We pride ourselves on a user-friendly experience that prioritizes efficiency and personalization. Our AI understands Scandinavian languages and tailors results to individual preferences. Whether a local resident or an international visitor, Kluret is your gateway to a wealth of knowledge and resources.</p>
                                    <a href="/register" className='doufgrgsgodhisrf'>
                                        <div className='jihsdguiyuwohduf'>Try For Free</div>
                                    </a>
                                </div>
                            </div>



                            <div className='fefedfeg8rew9uifdh9e'>
                                <div className='ijwhurusdboibgupboehrsfger'>
                                    <div className='nweorhsfo3guoiilrjsfjoigh'>
                                        <h1>Example to one of our Avatar</h1>
                                    </div>

                                    <div className="oiwuygdfhgo3rjebfshire">
                                        <div className="onuwbgfr3iogi4ti">
                                            <div className='iwshgeifkjghiueh'>
                                                <img src={jakob_svg} alt="" />
                                            </div>
                                            <div className='j0whhgutfkngju9eutuij'>
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                                            </div>
                                            <div className='ojihwugysdiuhkjkprji0ehsfugj'>
                                                <div className='oksjidhuhighkergf'>
                                                    <div>
                                                        
                                                    <div className='oppjioudhgjnrhuhi'>
                                                        <img src={iphone_emulator_svg} alt="" />
                                                    </div>
                                                    <div className='ojihwusyidkgjhruishkg'>
                                                        <h1 className="iwubsidgrhiogd">
                                                            1. Describing my Problems
                                                        </h1>
                                                        <br />
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Est quae, nam tempora, molestias et impedit aliquam iusto, corporis nihil beatae deleniti magnam possimus sunt ad. Vero blanditiis repellendus libero numquam.</p>
                                                    </div>

                                                    
                                                    <div className='oppjioudhgjnrhuhi'>
                                                        <img src={iphone_emulator_svg} alt="" />
                                                    </div>
                                                    <div className='ojihwusyidkgjhruishkg'>
                                                        <h1 className="iwubsidgrhiogd">
                                                            1. Describing my Problems
                                                        </h1>
                                                        <br />
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Est quae, nam tempora, molestias et impedit aliquam iusto, corporis nihil beatae deleniti magnam possimus sunt ad. Vero blanditiis repellendus libero numquam.</p>
                                                    </div>

                                                    
                                                    <div className='oppjioudhgjnrhuhi'>
                                                        <img src={iphone_emulator_svg} alt="" />
                                                    </div>
                                                    <div className='ojihwusyidkgjhruishkg'>
                                                        <h1 className="iwubsidgrhiogd">
                                                            1. Describing my Problems
                                                        </h1>
                                                        <br />
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Est quae, nam tempora, molestias et impedit aliquam iusto, corporis nihil beatae deleniti magnam possimus sunt ad. Vero blanditiis repellendus libero numquam.</p>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>








                            <div className='jihusgisdukhlrneehhge'>
                                <div className='piosdguxdihugirnsiuo9'>
                                    <div className='jionfb9grey87y3ejfh98r'>
                                        <h1>Public In Scandinavia</h1>
                                    </div>
                                    <div className='wdson9ryg7395erosfd'>
                                        <div className='hwsdgu9887refshu39rf'>
                                            <div className='nwjedofybiuerwlsdijn'>
                                                <div>
                                                    <img src={sweden_svg} alt="" />
                                                </div>
                                                <div>
                                                    <h1>Sweden</h1>
                                                </div>
                                            </div>
                                            <div>
                                                <img src={sweden_img} alt="" />
                                            </div>
                                        </div>
                                        <div className='jwi0sg97h3re739usf'>
                                            <div className='wdnofg93wr9u9h3r8y'>
                                                <div>
                                                    <img src={denmark_svg} alt="" />
                                                </div>
                                                <div>
                                                    <h1>Denmark</h1>
                                                </div>
                                            </div>
                                            <div>
                                                <img src={denmark_img} alt="" />
                                            </div>
                                        </div>
                                        <div className='owjish9g3erisr83h5rf'>
                                            <div>
                                                <div className='dwbif8wgr3ysuff4'>
                                                    <div>
                                                        <img src={norway_svg} alt="" />
                                                    </div>
                                                    <div>
                                                        <h1>Norway</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <img src={norway_img} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className='nOlxYgoWJXpIGAP6cUm8'>
                                <div>
                                    <div className='jidohlfjnugisfn'>
                                        <div>
                                            <img src={instagram_svg} alt="" />
                                        </div>
                                        <div>
                                            <img src={tiktok_svg} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='hgfhjgfgrhkfsjtru'>
                                    <footer>
                                        &copy; 2024 Kluret.com
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
}

export default HomeKluret;