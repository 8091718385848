import React, { useEffect, useState } from 'react';
import kluret_white_svg from '../../../__whitepaper__/images/kluret_wt.svg';
import '../../whitepaper_img/whitepaper.css';
import './nav.css'



function Nav(){
    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuVisible(!isMobileMenuVisible);
    };

    return(
        <>
            <nav className="flex justify-between items-center " id="iofjhewopgijopelfohghk3prej">
                <div className="jwiohfusiyfgwiosuhjfwihbfbduiwhajbiheyfdwgiuagfefhwsfiurfkhwis">
                    <div className="flex items-center">
                        <a href="/">
                            <img src={kluret_white_svg} alt="Kluret Logo" className="hoeuogljkewhdjpjgeiojfpegwltudigjewplnthjepdl" />
                        </a>
                    </div>
                    <div className="__nav__desctop">
                        <div className="__doj49joed9f4rdwsffki59rj3hr_E9fj494jfdofk4">
                            <a href="/" className="">What's Kluret</a>
                        </div>
                        <div className="__doj49joed9f4rdwsffki59rj3hr_E9fj494jfdofk4">
                            <a href="/latests" className="">Latests Updates</a>
                        </div>
                        <div className="__doj49joed9f4rdwsffki59rj3hr_E9fj494jfdofk4">
                            <a href="/partner" className="">Partners</a>
                        </div>
                        <div className="__doj49joed9f4rdwsffki59rj3hr_E9fj494jfdofk4">
                            <a href="/team" className="">Our Team</a>
                        </div>
                        <div className="__external_link__335ref">
                            <a href="/policy" className="">
                                Terms & Conditions
                                <span className="__tpxwld__">→</span>
                            </a>
                        </div>
                        <div className="__external_link__335ref">
                            <a href="/chat" className="">
                                Kluret Chat
                                <span className="__chatwithus__39kjfd99">
                                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
                                        <path d="M8 10.5H16" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"></path>
                                        <path d="M8 14H13.5" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"></path>
                                        <path d="M17 3.33782C15.5291 2.48697 13.8214 2 12 2C6.47715 2 2 6.47715 2 12C2 13.5997 2.37562 15.1116 3.04346 16.4525C3.22094 16.8088 3.28001 17.2161 3.17712 17.6006L2.58151 19.8267C2.32295 20.793 3.20701 21.677 4.17335 21.4185L6.39939 20.8229C6.78393 20.72 7.19121 20.7791 7.54753 20.9565C8.88837 21.6244 10.4003 22 12 22C17.5228 22 22 17.5228 22 12C22 10.1786 21.513 8.47087 20.6622 7" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"></path>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                    <div className="__nav__mobile" onClick={toggleMobileMenu}>
                        <div className="__nav__line__"></div>
                        <div className="__nav__line__"></div>
                        <div className="__nav__line__"></div>
                    </div>
                </div>
                
                <br />

                <div className={`__nav__mobile__links ${isMobileMenuVisible ? 'visible' : 'hidden'}`} style={{ transition: 'max-height 0.3s ease', maxHeight: isMobileMenuVisible ? '500px' : '0', overflow: 'hidden' }}>
                    <div className="__modbile">
                        <div className="__doj49joed9f4rdwsffki59rj3hr_E9fj494jfdofk4_mobile">
                            <a href='/'>
                                <div>What's Kluret</div>
                            </a>
                        </div>
                        <div className="__doj49joed9f4rdwsffki59rj3hr_E9fj494jfdofk4_mobile">
                            <a href="/latests" className="">
                                <div>Latests Updates</div>
                            </a>
                        </div>
                        <div className="__doj49joed9f4rdwsffki59rj3hr_E9fj494jfdofk4_mobile">
                            <a href="/policy" className="">
                                <div>Platform Guide-lines</div>
                            </a>
                        </div>
                        <div className="__external_link__335ref_44d">
                            <a href="/login" className="">
                                LogIn
                                <span className="__tpxwld__">→</span>
                            </a>
                        </div>
                        <div className="__external_link__335ref_44d">
                            <a href="/register" className="">
                                Register
                                <span className="__tpxwld__">→</span>
                            </a>
                        </div>
                        <div className="__external_link__335ref_44d">
                            <a href="/chat" className="">
                                Kluret Chat
                                <span className="__chatwithus__39kjfd99">
                                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
                                        <path d="M8 10.5H16" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"></path>
                                        <path d="M8 14H13.5" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"></path>
                                        <path d="M17 3.33782C15.5291 2.48697 13.8214 2 12 2C6.47715 2 2 6.47715 2 12C2 13.5997 2.37562 15.1116 3.04346 16.4525C3.22094 16.8088 3.28001 17.2161 3.17712 17.6006L2.58151 19.8267C2.32295 20.793 3.20701 21.677 4.17335 21.4185L6.39939 20.8229C6.78393 20.72 7.19121 20.7791 7.54753 20.9565C8.88837 21.6244 10.4003 22 12 22C17.5228 22 22 17.5228 22 12C22 10.1786 21.513 8.47087 20.6622 7" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round"></path>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Nav;