import React, { useState } from 'react';
import '../styling/aboutkluret.css';

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleDropdown = index => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div style={styles.container} id='__aboutcontainers__39ehf949urf'>
            <div style={styles.info} onClick={() => toggleDropdown(0)} className='oijofnghrofdjir0n4rnieri'>
                <h1 style={styles.heading}>What is Kluret?</h1>
                {activeIndex === 0 && (
                    <div style={styles.dropdown}>
                        Kluret is an advanced AI-driven search engine specifically designed for the European Union, with a particular focus on Sweden. Unlike traditional search engines, Kluret is powered by a sophisticated AI model that has been trained on vast datasets from various major platforms. We have been permitted to train our model using data from Google Images, AliExpress, Bing Images, Alibaba, and Temu, giving our AI an unparalleled understanding of global e-commerce products.

                        Kluret’s AI doesn’t just recognize and recommend products; it learns from historical data to create predictions about what products users might want in the future. By analyzing millions of images and product descriptions from these platforms, Kluret is able to understand design trends, price fluctuations, and consumer preferences. Over time, the AI can even create its own version of potential future products, based on past learning and market insights. This capability gives Kluret the unique ability to not just react to current demand but to predict and influence future shopping trends.
                    </div>
                )}
            </div>

            <div style={styles.info} onClick={() => toggleDropdown(1)} className='oijofnghrofdjir0n4rnieri'>
                <h1 style={styles.heading}>How does Kluret's AI learn from e-commerce data?</h1>
                {activeIndex === 1 && (
                    <div style={styles.dropdown}>
                        Kluret’s AI is trained on massive amounts of e-commerce data from platforms like AliExpress, Alibaba, Google Images, Bing Images, and Temu. These platforms have given us the ability to train our AI on real-world product images and descriptions. Our AI learns to recognize patterns in product design, consumer preferences, and even market trends by analyzing product listings, prices, images, and user behavior.

                        With the ability to study visual and textual data across a wide range of product categories, Kluret’s AI becomes adept at recognizing product similarities, quality levels, and potential market demands. This continuous learning process enables the AI to predict which products are likely to trend and which items users may want to purchase based on past patterns. Over time, Kluret’s AI can even generate product ideas based on learned trends, creating a visionary tool that goes beyond standard e-commerce searches.
                    </div>
                )}
            </div>

            <div style={styles.info} onClick={() => toggleDropdown(2)} className='oijofnghrofdjir0n4rnieri'>
                <h1 style={styles.heading}>How does Kluret use predictive analytics?</h1>
                {activeIndex === 2 && (
                    <div style={styles.dropdown}>
                        Predictive analytics is at the heart of Kluret’s functionality. By learning from a combination of e-commerce data from sources like AliExpress, Google Images, and Alibaba, Kluret’s AI is able to forecast future consumer behavior. This predictive model takes into account not just the current popularity of products but also trends from the past, allowing the AI to anticipate what users will want to purchase next.

                        Kluret’s ability to predict product trends goes beyond simple recommendations. Its AI can analyze massive data sets to recognize the signals of an upcoming product launch, new design trends, or shifts in consumer preferences. For instance, if a certain type of smartwatch becomes popular, Kluret’s AI can predict that similar products with enhanced features may soon hit the market. This allows users to stay ahead of the curve and discover new products before they become mainstream.
                    </div>
                )}
            </div>

            <div style={styles.info} onClick={() => toggleDropdown(3)} className='oijofnghrofdjir0n4rnieri'>
                <h1 style={styles.heading}>How does Kluret's image search feature work?</h1>
                {activeIndex === 3 && (
                    <div style={styles.dropdown}>
                        Kluret’s image search feature is powered by AI trained on image datasets from platforms like Google Images, Bing Images, AliExpress, and Alibaba. By learning from millions of product images, Kluret’s AI has become incredibly adept at recognizing product features such as design, color, texture, and patterns. Users can upload a picture of a product they’re interested in, and Kluret will use its visual recognition capabilities to find similar or identical items.

                        This image search functionality isn’t limited to exact matches. Kluret’s AI has been trained to understand the subtle design variations that occur across product lines. For example, if you upload an image of a popular sneaker, Kluret may suggest not only that sneaker but also other similar models that match the design elements you’re looking for. This makes Kluret an incredibly powerful tool for visually searching for products, even when users don’t know the exact name or details of the item they want to find.
                    </div>
                )}
            </div>

            <div style={styles.info} onClick={() => toggleDropdown(4)} className='oijofnghrofdjir0n4rnieri'>
                <h1 style={styles.heading}>What makes Kluret's AI unique?</h1>
                {activeIndex === 4 && (
                    <div style={styles.dropdown}>
                        Kluret’s AI stands out due to its deep training on a wide variety of e-commerce platforms, including major marketplaces like AliExpress, Alibaba, and image databases such as Google Images and Bing Images. This extensive training allows Kluret’s AI to offer something unique: the ability to predict future consumer needs. While many search engines can provide recommendations based on past behavior, Kluret takes it a step further by learning from historical data and creating product versions that match potential future demand.

                        Additionally, Kluret’s image recognition engine, trained on millions of product photos, can identify even the smallest details in an item’s design. This deep level of understanding, combined with predictive analytics, allows Kluret to offer a more comprehensive and forward-thinking shopping experience. The AI’s ability to anticipate trends and suggest products that haven’t yet become popular ensures that users always stay ahead of the curve when searching for new items.
                    </div>
                )}
            </div>

            <div style={styles.info} onClick={() => toggleDropdown(5)} className='oijofnghrofdjir0n4rnieri'>
                <h1 style={styles.heading}>How does Kluret handle user privacy and data security?</h1>
                {activeIndex === 5 && (
                    <div style={styles.dropdown}>
                        Kluret takes user privacy and data security very seriously. We adhere strictly to GDPR guidelines to ensure that all personal data is handled with care and transparency. User data is encrypted, and only authorized processes have access to it, ensuring that your personal information remains secure at all times.

                        In addition to encryption, Kluret’s systems are built to detect and prevent any unauthorized access. We do not share user data with third parties unless explicitly required, and users have full control over their data, including the ability to request data deletion at any time. By maintaining a commitment to security, Kluret ensures that users can enjoy a seamless shopping experience without compromising their privacy.
                    </div>
                )}
            </div>

            <div style={styles.info} onClick={() => toggleDropdown(6)} className='oijofnghrofdjir0n4rnieri'>
                <h1 style={styles.heading}>How often is Kluret's product database updated?</h1>
                {activeIndex === 6 && (
                    <div style={styles.dropdown}>
                        Kluret’s product database is continuously updated in real time. Our AI is constantly crawling connected e-commerce platforms such as AliExpress, Alibaba, and Temu to retrieve the latest product data, price changes, and availability. This ensures that users are always presented with the most current information during their searches.

                        By keeping the database updated regularly, Kluret can offer real-time insights into product trends and price fluctuations. This allows users to make more informed purchasing decisions, ensuring they get the best deals on the products they’re interested in. Kluret’s commitment to real-time updates ensures that the platform remains one of the most reliable sources for up-to-date e-commerce information.
                    </div>
                )}
            </div>

            <div style={styles.info} onClick={() => toggleDropdown(7)} className='oijofnghrofdjir0n4rnieri'>
                <h1 style={styles.heading}>Can Kluret be used for visual product searches?</h1>
                {activeIndex === 7 && (
                    <div style={styles.dropdown}>
                        Yes, Kluret's advanced image search capability allows users to upload images and find similar products. By leveraging its training on millions of images from AliExpress, Google, Bing, and other platforms, Kluret's visual search engine can identify key product features like color, shape, and design. This makes it an incredibly powerful tool for finding items when users may not know the name or details of a product.

                        Whether it’s a piece of clothing, a piece of furniture, or an electronic device, users can simply upload an image, and Kluret will use its AI to locate matching or similar products. The image search feature enhances the overall shopping experience, providing users with quick and intuitive product discovery.
                    </div>
                )}
            </div>

            <div style={styles.info} onClick={() => toggleDropdown(8)} className='oijofnghrofdjir0n4rnieri'>
                <h1 style={styles.heading}>Can Kluret be used outside of Sweden?</h1>
                {activeIndex === 8 && (
                    <div style={styles.dropdown}>
                        While Kluret is currently focused on the Swedish and broader EU markets, it has plans to expand its reach to other countries in the near future. The platform is designed with scalability in mind, meaning it can be easily adapted to different markets by incorporating local e-commerce platforms and customizing its search capabilities to reflect the specific needs of each region.

                        Kluret’s development team is continuously working on localizing the AI model to make it more applicable to non-Swedish markets. This includes adding language options, integrating new payment gateways, and ensuring that product availability and delivery options align with users in different countries. Eventually, Kluret aims to become a global e-commerce solution, offering predictive search capabilities to users around the world.
                    </div>
                )}
            </div>

            <div style={styles.info} onClick={() => toggleDropdown(9)} className='oijofnghrofdjir0n4rnieri'>
                <h1 style={styles.heading}>Is Kluret planning to expand its services outside Sweden?</h1>
                {activeIndex === 9 && (
                    <div style={styles.dropdown}>
                        Yes, Kluret plans to expand its services to other countries in the European Union, focusing on providing localized search capabilities. By integrating with local e-commerce platforms and adapting its AI to understand regional shopping behaviors, Kluret aims to become a key player in the EU's digital marketplace. Future developments will likely include language customization, localized shipping options, and partnerships with more EU-based e-commerce platforms to cater to the diverse needs of European shoppers.
                    </div>
                )}
            </div>

            <br /><br /><br /><br /><br /><br /><br />
        </div>
    );
};

const styles = {
    container: {
        padding: '0px',
        fontFamily: "'Jura', sans-serif"
    },
    info: {
        marginBottom: '15px',
        cursor: 'pointer',
    },
    heading: {
        fontSize: '15px',
        margin: '10px 0',
    },
    dropdown: {
        padding: '10px',
        border: '1px solid white',
        borderRadius: '5px',
        fontSize: '13px',
        backgroundColor: '#f9f9f9',
        color: 'black'
    },
};

export default FAQ;
