import React, { useState, useEffect } from 'react';
import close_svg from '../../tamplates/images/close.svg';
import '../styling/profile.css';
import CartTemplate2 from './cart_template2'; // Assuming this is the real component for Cart
import ReturnsTemplate from './cart_template2'; // Dummy component for Returns
import UserSettings from './user_settings';
import Myshippiings from './myshippiings';
import ShippingTemplate from './cart_template2'; // Dummy component for Shipping
import AuthPage from '../../auth/auth'; // Auth component for login/signup
import shopping_cart_svg from '../styling/imges/shoppingcart.svg'
import profile_icone_svg from '../styling/imges/profile_profile.svg'
import logout_icone_svg from '../styling/imges/logout_icone.svg'



function ProfileKluret({ onClose }) {
    const [activeSection, setActiveSection] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showAuthPopup, setShowAuthPopup] = useState(false); // State to show auth popup
    const [authChanged, setAuthChanged] = useState(false); // State to track if auth status changed

    useEffect(() => {
        // Check if the user is logged in (this is just an example)
        const sessionToken = localStorage.getItem('sessionToken');
        if (sessionToken) {
            setIsLoggedIn(true);
        }
    }, [authChanged]); // Re-run when authChanged updates

    const handleNavClick = (section) => {
        if (isLoggedIn) {
            setActiveSection(section);
            setShowPopup(true);
        } else {
            // When the user clicks Login or Register
            localStorage.setItem('letschat_auth', 'false'); // Set the variable in localStorage
            setShowAuthPopup(true); // Show auth popup if not logged in
        }
    };

    const handleRegisterClick = () => {
        localStorage.setItem('register', 'registerComponent');
        setTimeout(() => {
            localStorage.removeItem('register');
        }, 5000);
    };
    
    const handleLoginClick = () => {
        localStorage.setItem('register', 'logInComponent');
        setTimeout(() => {
            localStorage.removeItem('register');
        }, 5000);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setActiveSection(''); // Reset active section when closing the popup
    };

    const handleTouchEnd = (event) => {
        event.preventDefault(); // Prevent any default touch behavior
        handleClosePopup(); 
    };

    const handleLogout = () => {
        const confirmed = window.confirm("Are you sure you want to logout?");
    
        if (confirmed) {
            // If the user confirms, clear the user's session and log them out
            localStorage.removeItem('sessionToken');
            localStorage.removeItem('cartItems');
            localStorage.removeItem('totalCost');
            localStorage.removeItem('userID');
            setIsLoggedIn(false);
            handleClosePopup();
    
            // Optionally navigate to /chat after clearing the session
            window.location.href = '/chat';
        } 
        // If the user cancels, do nothing
    };
    

    const closeAuthPopup = () => {
        setShowAuthPopup(false);
        setAuthChanged(prev => !prev); // Trigger re-render
    };

    useEffect(() => {
        // Every second, check if letschat_auth is set to true and close the auth popup
        const intervalId = setInterval(() => {
            const authStatus = localStorage.getItem('letschat_auth');
            if (authStatus === 'true') {
                closeAuthPopup();
                localStorage.removeItem('letschat_auth'); // Remove the variable from localStorage
            }
        }, 1000);

        return () => clearInterval(intervalId); // Clean up on component unmount
    }, []);

    const renderPopupContent = () => {
        switch (activeSection) {
            case 'Cart':
                return <CartTemplate2 />;
            case 'Returns':
                return <ReturnsTemplate />;
            case 'My Data':
                return <UserSettings />;
            case 'Shipping':
                return <ShippingTemplate />;
            case 'Myshippiings':
                return <Myshippiings />;
            default:
                return null;
        }
    };

    return (
        <div className="profile-container">
            <div className="nav-container">
                {isLoggedIn ? (
                    <>
                        <div onClick={() => handleNavClick('Cart')}>
                            <a className={`profile-tab ${activeSection === 'Cart' ? 'active' : ''}`}>
                                <img src={shopping_cart_svg} alt="" />
                                Shopping Cart
                            </a>
                        </div>
                        <div onClick={() => handleNavClick('Myshippiings')}>
                            <a className={`profile-tab ${activeSection === 'Myshippiings' ? 'active' : ''}`}>
                                <img src="https://www.kluret.se/static/media/leveranse.1c61f4486913be29f9dc5cb1e12dca95.svg" width="24px" alt="" />
                                My Shippings
                            </a>
                        </div>
                        <div onClick={() => handleNavClick('My Data')}>
                            <a className={`profile-tab ${activeSection === 'My Data' ? 'active' : ''}`}>
                                <img src={profile_icone_svg} alt="" />
                                Profile Settings
                            </a>
                        </div>
                        <div onClick={handleLogout}>
                            <a className="profile-tab">
                                <img src={logout_icone_svg} alt="" />
                                <p className='color__ojshiefjdssss'>Logout</p>
                            </a>
                        </div>
                    </>
                ) : (
                    <>
                        <div onClick={() => { handleNavClick('Login'); handleLoginClick(); }}>
                            <a className="profile-tab">
                                Login
                            </a>
                        </div>
                        <div onClick={() => { handleNavClick('Register'); handleRegisterClick(); }}>
                            <a className="profile-tab">
                                Register
                            </a>
                        </div>
                    </>
                )}

                {/* <div onClick={onClose} className='__dej9fidkgirudnkj__close'>
                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="black"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z" fill="#ffffff"></path> </g></svg>
                </div> */}
            </div>
            {showPopup && (
                <div className="popup-overlay active">
                    <div className="popup-header">
                        <img 
                            src={close_svg} 
                            width="25px" 
                            alt="Close" 
                            onClick={handleClosePopup} 
                            onTouchEnd={handleTouchEnd} // Added touch event
                            className="close-popup-button"
                        />
                    </div>
                    <div className="popup-content">
                        {renderPopupContent()}
                    </div>
                </div>
            )}
            {showAuthPopup && (
                <div
                    className="auth-popup-overlay active"
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100vh',
                        zIndex: 9999, // Ensure it's on top of all other content
                        backgroundColor: 'black' // Optional: darken the background
                    }}
                >
                    <div
                        className="auth-popup-header"
                        style={{
                            position: 'relative',
                            backgroundColor: 'black', // Ensure background is white
                        }}
                    >
                        <img 
                            src={close_svg} 
                            width="25px" 
                            alt="Close" 
                            onClick={closeAuthPopup} 
                            className="close-auth-popup-button"
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                cursor: 'pointer'
                            }}
                        />
                    </div>
                    <div className="auth-popup-content" style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <AuthPage />
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProfileKluret;
