import React, { useEffect, useState } from 'react';





function Bottomnav(){


    return(
        <>

            <div className="dkwihssdie0fi0ge0jfocfkojwdfihkgf">
                <div className="hsj_1"></div>
                <div className="hsj_2"></div>
                <div className="hsj_3"></div>
                <div className="hsj_4"></div>
                <div className="hsj_5"></div>
                <div className="hsj_6"></div>
            </div>

            <div className="flooter__section_oej4">
                <div className="flooter__section_oej4_holder">
                    <div className="terminal_1odssjfej">
                        <div>
                            <h1>Platform</h1>
                            <br />
                            <a href="/#___how_itworks__9jer4">How Kluret works</a>
                        </div>
                        <div>
                            <a href="/faq">FAQs</a>
                        </div>
                        <div>
                            <a href="mailto:hej@kluret.se">Report a Problem</a>
                        </div>
                        <div>
                            <a href="/policy">Terms & Conditions</a>
                        </div>
                        <div>
                            <a href="/chat">Kluret Chat</a>
                        </div>
                    </div>
                    <div className="terminal_1odssjfej">
                        <div>
                            <h1>Company</h1>
                            <br />
                            <a href="/team">Our Team</a>
                        </div>
                        <div>
                            <a href="/partner">Partners</a>
                        </div>
                        <div>
                            <a href="">Contact Us</a>
                        </div>
                    </div>
                    <div className="__socialnetworks_er4rfdf">
                        <h1 className="_netwroks_3eu">Social Networks</h1>
                        <div className="socialnetworkss">
                            <a href="https://www.instagram.com/kluret_network/">
                                <div>
                                    <img src="https://www.svgrepo.com/show/452229/instagram-1.svg" alt="" />
                                </div>
                            </a>
                            <a href="https://www.tiktok.com/@kluret_ab">
                                <div>
                                    <img src="https://www.svgrepo.com/show/452114/tiktok.svg" alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="allrightsreserved text-center py-4">
                    &copy; Kluret 2023. All rights reserved.
                </div>

            </div>
        </>
    )
}


export default Bottomnav;