import { defer } from 'react-router-dom'
import omkluret_svg from '../images/omkluret.svg'
import '../styling/style.css'

function Omkluret(){
    return(
        <>
            <div className='pehsohgeuifogef9ioeidg9uioe0f'>
                <div>
                    <div className='_____diiv___________'>
                        <div className='__img__1'>
                            <img src="https://media.istockphoto.com/id/1530802302/sv/foto/focus-hand-of-businessman-show-virtual-graphic-data-connect-with-ai-using-ai-to-sucess.jpg?s=612x612&w=0&k=20&c=d4SMyUJj_kr4N1BbK8Mts_R6LitDWo-w_YaUFIMnVRU=" alt="" />
                        </div>
                        <h2 className='____title_______'>Introduktion till Kluret</h2>
                        <p>Kluret är en innovativ AI-baserad plattform som revolutionerar online-shopping genom att erbjuda en mer intelligent och användarvänlig sökupplevelse. Plattformen använder avancerad artificiell intelligens (AI) och naturlig språkbehandling (NLP) för att förstå och tolka komplexa frågor på ett sätt som traditionella sökmotorer inte kan. Grundarna, Elias Luzwehimana och Ernest Itangishaka, har utvecklat Kluret med visionen att göra online-shopping mer intuitiv och anpassad till användarens naturliga sätt att kommunicera.</p>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className='_____diiv___________'>
                        <div className='__img__1'>
                            <img src="https://media.istockphoto.com/id/2149039702/sv/foto/neural-network-nodes-deep-learning-artificial-intelligence-machine-learning-model.jpg?s=612x612&w=0&k=20&c=Kchut7G_9e6uL6uvHDDwNUhgJCGF--6wvVHxp6Q28ww=" alt="" />
                        </div>
                        <h2 className='____title_______'>Personlig och Precisionsinriktad Sökmotor</h2>
                        <p>Kluret adresserar utmaningarna med traditionella sökmotorer genom att erbjuda en sökupplevelse som går bortom enkla nyckelordsmatcher. Istället för att enbart fokusera på ord, förstår Kluret avsikten bakom användarens sökningar. Detta innebär att plattformen kan leverera relevanta och skräddarsydda resultat, vilket gör online-shopping smidigare och mer tillfredsställande. Användarna kan uttrycka sina behov på sitt eget sätt och få träffsäkra rekommendationer baserat på deras specifika behov och preferenser.</p>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className='_____diiv___________'>
                        <div className='__img__1'>
                            <img src="https://media.istockphoto.com/id/2084953049/sv/foto/neural-network-nodes-deep-learning-artificial-intelligence-machine-learning-model.jpg?s=612x612&w=0&k=20&c=7FulGA7OcTnm1xQ9e-B0xsTal6vUKnwrTUeqVMfSnns=" alt="" />
                        </div>
                        <h2 className='____title_______'>Optimerad för Svenska Marknaden</h2>
                        <p>En av Klurets unika styrkor är dess anpassning till den svenska marknaden. Plattformen tar hänsyn till språkliga och kulturella nyanser, vilket säkerställer att svenska användare får de mest relevanta och exakta resultaten. Detta lokaliserade tillvägagångssätt är avgörande för att Kluret ska kunna erbjuda en sökmotor som känns både lokal och användarcentrerad. Genom att kombinera AI-teknologi med Googles sökmotor kan Kluret möta de specifika behoven hos svenska konsumenter, vilket gör plattformen till ett oumbärligt verktyg för online-shopping i Sverige.</p>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className='_____diiv___________'>
                        <div className='__img__1'>
                            <img src="https://media.istockphoto.com/id/1473166916/sv/foto/concept-of-cloud-technologies-artificial-intelligence-blockchain-technologies-big-data-cube.jpg?s=612x612&w=0&k=20&c=YrLffSSavML5gxMpYT-EeTfggQEWcyC9vd2locIDWO8=" alt="" />
                        </div>
                        <h2 className='____title_______'>Framtida Expansion och Innovation</h2>
                        <p>Kluret har som mål att expandera globalt och kontinuerligt förbättra sina AI-funktioner för att skapa en global användarbas som litar på plattformen för deras online-inköpsbehov. Denna expansion kommer att innebära en lokal anpassning för olika marknader världen över, där Kluret kommer att fortsätta utveckla sina AI-algoritmer och integrera nya teknologier. Genom innovation och anpassning kommer Kluret att fortsätta förändra sättet människor shoppar online, både i Sverige och internationellt.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Omkluret;
