import React from 'react';
import { Routes, Route} from 'react-router-dom';
import HomeKluret from './chat/public/home.jsx';
import Letschat from './chat/loggedin/letschat.jsx';
import Register from './chat/auth/register.jsx';
import LogIn from './chat/auth/login.jsx';
import Forgotpass from './chat/auth/forgotpass.jsx';
import PrivateRoute from './PrivateRoute.jsx';
import Intro from './chat/loggedin/intro.jsx';
import ProductSearch from './chat/auth/api.jsx';
import WhitePaper from './chat/loggedin/__main__/whitepaper.jsx';
import Network from './chat/loggedin/network.jsx';
import Ourteam from './chat/loggedin/__main__/whitepaper_img/white/compo/ourteam.jsx';
import Letests from './chat/loggedin/__main__/whitepaper_img/white/compo/latests.jsx';
import Partner from './chat/loggedin/__main__/whitepaper_img/white/compo/partners.jsx';
import Termsconditions from './chat/loggedin/__main__/whitepaper_img/white/compo/terms_conditions.jsx';
import Faqs from './chat/loggedin/__main__/whitepaper_img/white/compo/FAQs.jsx';
import Google_auth from './chat/auth/registerwithgoogle.jsx';


function App() {
    return (
        <div>
            <Routes>
                <Route path="/network" element={<Intro />} />
                {/* <Route path="/" element={<Letschat />} /> */}
                <Route path="/chat" element={<Letschat />} />
                <Route path="/requestcode" element={<Forgotpass />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<LogIn />} />
                <Route path="/e" element={<HomeKluret />} />
                <Route path="/ProductSearch" element={<ProductSearch />} />
                <Route path="/" element={<WhitePaper />} />
                <Route path="/team" element={<Ourteam />} />
                <Route path="/partner" element={<Partner />} />
                <Route path="/policy" element={<Termsconditions />} />
                <Route path="/latests" element={<Letests />} />
                <Route path="/faq" element={<Faqs />} />
                <Route path="/google" element={<Google_auth />} />
            </Routes>
        </div>
    );
}

export default App;
