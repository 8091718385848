import './styling/intro.css';
import kluret_svg from '../tamplates/images/logo.svg';
import { useState, useEffect } from 'react';

function Intro() {
    const [timeLeft, setTimeLeft] = useState({});
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        const targetDate = new Date();
        targetDate.setDate(targetDate.getDate() + 13);

        const interval = setInterval(() => {
            const now = new Date();
            const difference = targetDate - now;

            const timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 29)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };

            setTimeLeft(timeLeft);

            if (difference < 0) {
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const recordVisit = async () => {
            try {
                await fetch('https://usbaiclient-bolaget-e4ebc1f2bfad.herokuapp.com/notify/api/visitors/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ timestamp: new Date().toISOString() }),
                });
            } catch (error) {
                console.error('Error recording visit:', error);
            }
        };

        recordVisit();
    }, []);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        setMessage(''); // Clear any previous messages

        if (!email) {
            setMessage('Please enter an email address.');
            return;
        }

        //https://webenginegptai-f6919d4667cb.herokuapp.com

        try {
            const response = await fetch('https://usbaiclient-8c59f2a693e9.herokuapp.com/notify/api/emails/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setMessage('Email submitted successfully!');
                setEmail(''); // Clear the email input field
            } else {
                setMessage('Failed to submit email. Please try again.');
            }
        } catch (error) {
            setMessage('An error occurred. Please try again.');
        }
    };

    return (
        <>
            <div className="nouigyfuosjldjg">
                <div className="k6mAFVxe7hsc5tz2pXZSg">
                    <div className="drucyJH6WbQokemNZLxnijRI">
                        <img src={kluret_svg} alt="Kluret Logo" />
                    </div>
                    <div>
                        <h1 className='juhygiuhlgjoebi'>Kluret || Free AI Search Engine</h1>
                        <p className='ko0iu9yguibjfre'>Runing on the Swedish WEB.2.0 Network</p>
                        <br /><br /><br />
                        {/* <div className='ojpiobivuwggf9h80gh8wyr'>
                            <a href="/" className='pdjihoubiwluhihp94r8ghowp0ghp'>Try Kluret for free!</a>
                        </div> */}
                    </div>
                    <div className="notification">
                        <h1 className='jjihugyf8td7f8g89y08e9hfu'>Notice!</h1>
                        <p>Klure AI will be released after thorough software testing with a small group of people and government authorities to ensure it meets high-quality standards.</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Intro;
