import React, { useState } from "react";
import Bottomnav from "./botom_nav";
import Nav from "../nav";
import './style/outlet.css';

function Partner() {

    // State to handle the toggling of each section
    const [showFullText, setShowFullText] = useState({
        google: false,
        openai: false,
        microsoft: false,
        alibaba: false,
        aws: false,
        etsy: false,
        stripe: false,
    });

    const toggleText = (section) => {
        setShowFullText({ ...showFullText, [section]: !showFullText[section] });
    };

    const renderContent = (content, section) => {
        const shortText = content.split(' ').slice(0, 100).join(' ') + '...';
        return (
            <>
                <p className="mb-4">{showFullText[section] ? content : shortText}</p>
                <button 
                    className="text-blue-500" 
                    onClick={() => toggleText(section)}>
                    {showFullText[section] ? 'View Less' : 'View All'}
                </button>
            </>
        );
    };

    return (
        <>
            <div>
                <Nav />
                <div>
                    <br /><br /><br />
                </div>
                <div className="main_header_policy">

                    <div>
                        <br /><br /><br /><br /><br /><br />
                        <h1 className="text-4xl font-bold mb-6" id="__doe29w0srifogekof">Powered & Partnerships</h1>
                    </div>
                    <div>
                        <p>Kluret is proud to partner with leading companies in AI, cloud infrastructure, and e-commerce to bring the best product search experience to users in Sweden. These collaborations help us scale our services, enhance AI capabilities, and offer real-time, accurate product data.</p>
                    </div>
                </div>

                <div className="policy_nest_3004ij0kr">
                    <br /><br /><br /><br /><br /><br /><br /><br />
                    <div className="__policy_diiv">
                        <h2 className="text-2xl font-semibold mb-4">Powered by Google</h2>
                        {renderContent(
                            `Kluret leverages Google’s advanced search algorithms and cloud infrastructure to ensure fast and reliable search results. Our partnership with Google allows us to offer users unparalleled performance in finding the products they need. Google’s AI services help us deliver more precise recommendations and enhance the overall search experience.`,
                            'google')}
                    </div>

                    <div className="__policy_diiv">
                        <h2 className="text-2xl font-semibold mb-4">Powered by OpenAI</h2>
                        {renderContent(
                            `Our AI-based search engine is powered by OpenAI's state-of-the-art machine learning models. This enables Kluret to understand complex queries, provide better contextual product searches, and deliver highly relevant results. By integrating OpenAI’s GPT technology, we are constantly improving our ability to offer smart, conversational product recommendations.`,
                            'openai')}
                    </div>

                    <div className="__policy_diiv">
                        <h2 className="text-2xl font-semibold mb-4">Powered by Microsoft</h2>
                        {renderContent(
                            `With Microsoft Azure, Kluret ensures high availability, data security, and seamless scalability. Our collaboration with Microsoft enhances our AI models and provides the infrastructure needed to support millions of searches across the Swedish web, giving users a smooth and secure experience.`,
                            'microsoft')}
                    </div>

                    <div className="__policy_diiv">
                        <h2 className="text-2xl font-semibold mb-4">Powered by Alibaba</h2>
                        {renderContent(
                            `Kluret’s AI is trained on product images and data from Alibaba, which allows us to recognize a wide variety of products. This training helps our system generate accurate product comparisons, even for niche items. Our AI tracks product values across the Swedish web and creates its own product versions for users, improving the buying experience.`,
                            'alibaba')}
                    </div>

                    <div className="__policy_diiv">
                        <h2 className="text-2xl font-semibold mb-4">Powered by AWS EC2</h2>
                        {renderContent(
                            `Amazon Web Services (AWS) EC2 powers Kluret’s backend infrastructure, allowing us to dynamically scale resources based on user traffic. This ensures that searches remain fast and efficient, regardless of the number of users online. AWS EC2 also enhances our product data processing capabilities, contributing to accurate and quick results.`,
                            'aws')}
                    </div>

                    <div className="__policy_diiv">
                        <h2 className="text-2xl font-semibold mb-4">Powered by Etsy</h2>
                        {renderContent(
                            `Through our partnership with Etsy, Kluret is able to offer a wide range of unique and handmade products for users to browse. Our AI connects directly with Etsy’s API to deliver real-time product availability and pricing, making it easier for users to discover one-of-a-kind items on the Swedish market.`,
                            'etsy')}
                    </div>

                    <div className="__policy_diiv">
                        <h2 className="text-2xl font-semibold mb-4">Powered by Stripe</h2>
                        {renderContent(
                            `Kluret integrates with Stripe to facilitate secure and seamless payment processing for e-commerce transactions. Through Stripe, users can confidently make payments knowing that their information is protected by top-level encryption and fraud prevention technology.`,
                            'stripe')}
                    </div>
                </div>
        
                <div className="__bottom__399fr9j">
                    <Bottomnav />
                </div>
            </div>
        </>
    )
}

export default Partner;
