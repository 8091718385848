import Nav from "../nav";
import './style/outlet.css';
import Bottomnav from "./botom_nav";
import ourteam_svg from './img/kluret_team.svg'

function Ourteam() {

    return (
        <>
            <div className="bg-black text-white">
                <Nav />
                <div>
                    <br /><br /><br />
                </div>
                <div className="ourteam-at-kluret">
                    <div className="main_header_policy text-center">
                        <div>
                            <h1 className="text-4xl font-bold mb-6 text-left">The team behind Kluret</h1>
                        </div>
                        <div>
                            <p className="text-left">Meet the talented team behind Kluret, the first AI-powered product search engine. Our co-founders have combined their expertise in AI, technology, and e-commerce to revolutionize the way people search for products online.</p>
                        </div>
                    </div>
                    <div className=" mt-8">
                        <div className="policy_nest_3004ij0krmm flex flex-wrap justify-center gap-1">
                            
                            <div className="__init__div flex flex-col items-center bg-black border border-gray-700 rounded-lg p-6 m-4 w-2/5">
                                <div className="mb-4">
                                    <img className="w-32 h-32 rounded-full object-cover" src="https://media.istockphoto.com/id/1289322871/sv/video/3d-animation-av-en-kvinna-xray-hologram-som-visar-sm%C3%A4rta-omr%C3%A5de-p%C3%A5-pannan-med-alfakanal.jpg?s=640x640&k=20&c=mC3h4MG-0Hijmxrz1MW9PbpJhRbq4RsEp-mfRZjPUmQ=" alt="Elias Luzwehimana" />
                                </div>
                                <div className="__team_demonstration text-center">
                                    <div>
                                        <h1 className="text-2xl font-semibold mb-1">Elias Luzwehimana</h1>
                                        <p className="font-light text-gray-400">Co-Founder, CEO & CTO</p>
                                    </div>
                                    <div className="mt-4 text-sm text-gray-300">
                                        <p className="text-left">Elias is a technology visionary with a deep background in AI and product development. He drives Kluret’s technical innovation and leads the team in building advanced AI-powered search functionalities that redefine product search experiences in Sweden.</p>
                                    </div>
                                    <br /><br />
                                    <a href="mailto:elias@kluret.se" className="__mail_us_at_kluret__">elias@kluret.se</a>
                                </div>
                            </div>

                            <div className="__init__div flex flex-col items-center bg-black border border-gray-700 rounded-lg p-6 m-4 w-2/5">
                                <div className="mb-4">
                                    <img className="w-32 h-32 rounded-full object-cover" src="https://media.istockphoto.com/id/1289322871/sv/video/3d-animation-av-en-kvinna-xray-hologram-som-visar-sm%C3%A4rta-omr%C3%A5de-p%C3%A5-pannan-med-alfakanal.jpg?s=640x640&k=20&c=mC3h4MG-0Hijmxrz1MW9PbpJhRbq4RsEp-mfRZjPUmQ=" alt="Ernest Itengishaka" />
                                </div>
                                <div className="__team_demonstration text-center">
                                    <div>
                                        <h1 className="text-2xl font-semibold mb-1">Ernest Itengishaka</h1>
                                        <p className="font-light text-gray-400">Co-Founder, SEO & BI Lead</p>
                                    </div>
                                    <div className="mt-4 text-sm text-gray-300">
                                        <p className="text-left">Ernest leads the business intelligence and SEO strategy at Kluret. His insights into market trends and search optimization ensure Kluret’s visibility and growth while delivering a seamless and highly relevant experience for users across the Swedish market.</p>
                                    </div>
                                    <br /><br />
                                    <a href="mailto:ernest@kluret.se" className="__mail_us_at_kluret__">ernest@kluret.se</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="__bottom__399fr9j">
                    <Bottomnav />
                </div>
            </div>
        </>
    )
}

export default Ourteam;
