import React, { useEffect, useRef, useState, useCallback } from 'react';
import '../styling/searchengine__.css';
import searchengine_svg from "../../tamplates/images/searchengine.svg";
import close_svg from "../styling/imges/close.svg";
import kluret_searchenginenetwork from "../styling/imges/kluretNetworksearchengine.svg";
import klarna_svg_oef from '../../tamplates/kluret.svg'
import loove_svg from '../../tamplates/images/loove.svg';
import cart_svgg from '../styling/imges/cart_img.svg';
import CartTemplate from './cart_template';
import AuthPage from '../../auth/auth';
import openai_svg from '../styling/imges/openai_svg.svg';
import google_svg from '../styling/imges/google.svg';
import stripe_svg from '../styling/imges/stripe.svg';
import filter_svg from '../styling/imges/filter.svg';
import leveranse_svg from './whitepaper_img/leveranse.svg';
import kluret_klarna_pay_svg from './whitepaper_img/kluret_klarna_pay.svg'
import payasyougo_svg from './whitepaper_img/payasyougo.svg'

function SearchEgine({ onClose }) {
    const divRef = useRef(null);
    const inputRef = useRef(null);
    const listRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [slowConnection, setSlowConnection] = useState(false);
    const [error, setError] = useState(null);
    const [pageIndex, setPageIndex] = useState(1);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showProductDetail, setShowProductDetail] = useState(false);
    const [fullScreenHeight, setFullScreenHeight] = useState('0vh');
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showCart, setShowCart] = useState(false);
    const [cartHeight, setCartHeight] = useState('0vh');
    const [showLogin, setShowLogin] = useState(false);
    const [isAddingToCart, setIsAddingToCart] = useState(false);
    const [addToCartError, setAddToCartError] = useState(false);
    const [isAddingToWishlist, setIsAddingToWishlist] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [descriptionLoading, setDescriptionLoading] = useState(true);
    const [imageLoading, setImageLoading] = useState(true);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [showFilters, setShowFilters] = useState(false);
    const [minPrice, setMinPrice] = useState(1);
    const [maxPrice, setMaxPrice] = useState(100000);
    const [userInput, setUserInput] = useState('');
    const [kluretResponse, setKluretResponse] = useState('');
    const [typing, setTyping] = useState(false);
    const [availableSizes, setAvailableSizes] = useState([]); 
    const [selectedSize, setSelectedSize] = useState(''); 
    const [sizePopupLoading, setSizePopupLoading] = useState(false); 
    const [addToCartSuccess, setAddToCartSuccess] = useState(false); 
    const [selectedPaymentPlan, setSelectedPaymentPlan] = useState('30-days'); 

    
    // Effect to detect product name and price from localStorage
    useEffect(() => {
        const intervalId = setInterval(() => {
            const storedProductName = localStorage.getItem('productName');
            const storedProductPrice = localStorage.getItem('productPrice');
            
            if (storedProductName && storedProductName !== searchQuery) {
                setSearchQuery(storedProductName);

                // If storedProductPrice is available, set it as maxPrice before searching
                if (storedProductPrice) {
                    const price = parseInt(storedProductPrice, 10);
                    if (!isNaN(price)) {
                        setMaxPrice(price);
                    }
                }
                
                handleSearch(storedProductName);
                
                // Clear the stored values after using them
                localStorage.setItem("productName", '');
                localStorage.setItem("productPrice", '');
                console.log(`Stored Product Price: ${storedProductPrice}`);
            }
        }, 1000);
    
        return () => clearInterval(intervalId);
    }, [searchQuery]);

    const getCSRFToken = () => {
        const csrfToken = document.cookie.split(';').find(cookie => cookie.trim().startsWith('csrftoken='));
        return csrfToken ? csrfToken.split('=')[1] : '';
    };

    const fetchProducts = useCallback(async (productName, pageIndex) => {
        console.log('Fetching products...', productName, pageIndex);
        setSlowConnection(false);
    
        const trackSearchStatus = async (status, totalProducts = 0) => {
            try {
                const response = await fetch('https://customerserver1-5d81976997ba.herokuapp.com/searchengine_tracking/track_search/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        // 'X-API-KEY': 'eiojwhd93reugh9euf0ehdovivfkcjgfheodfoeoigjeuffhuhooh',
                    },
                    body: JSON.stringify({
                        product_name: productName,
                        response_status: status,
                        total_products_found: totalProducts
                    })
                });
    
                if (!response.ok) {
                    console.error('Failed to track search status');
                }
            } catch (error) {
                console.error('Error sending tracking data:', error);
            }
        };
    
        if (pageIndex === 1) {
            setLoading(true);
        } else {
            setLoadingMore(true);
        }
    
        const slowConnectionTimeout = setTimeout(() => {
            setSlowConnection(true);
        }, 5000);
    
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    
        const fetchWithRetry = async () => {
            let retryCount = 0;
            const maxRetries = 10;
    
            const makeFetchRequest = async () => {
                try {
                    const formData = new FormData();
                    formData.append('product_name', productName);
                    formData.append('page_index', pageIndex);
                    formData.append('min_price', minPrice);
                    formData.append('max_price', maxPrice);
    
                    const response = await fetch('https://engine1-f36f7fb18f56.herokuapp.com/openai_google_computing/jdb/', {
                        //https://webnodes-bolaget-3d5cb2e15aa3.herokuapp.com
                        method: 'POST',
                        body: formData,
                        headers: {
                            'X-CSRFToken': getCSRFToken(),
                        },
                    });
    
                    clearTimeout(slowConnectionTimeout);
    
                    if (response.ok) {
                        const data = await response.json();
    
                        if (Array.isArray(data) && data.length > 0) {
                            const productsList = data.map((product, index) => ({
                                id: product.product_page_url?.split('/').pop() || `product-${index}`,
                                name: product.name || 'No Name Provided',
                                price: product.price || 'No Price Provided',
                                product_page: product.product_page_url,
                                cover_image: product.cover_image_url || 'placeholder_image_url',
                                color: product.color || '',
                            }));
    
                            const filteredProducts = productsList.filter(newProduct => {
                                return !products.some(existingProduct => existingProduct.product_page === newProduct.product_page);
                            });
    
                            console.log('Filtered Products List:', filteredProducts);
    
                            setProducts(prevProducts => [...prevProducts, ...filteredProducts]);
    
                            // Track the success status and total products found
                            await trackSearchStatus('success', data.length);
    
                            return; // Process the first successful fetch, and stop further requests
                        } else {
                            throw new Error(`No products please`);
                            document.querySelector('.jihuiyufygiougiyfuyguiooiohugyfusi').click();
                        }
                    } else {
                        throw new Error(`Failed to fetch products`);
                        document.querySelector('.jihuiyufygiougiyfuyguiooiohugyfusi').click();
                    }
                } catch (error) {
                    console.error('Error fetching products:', error);
                    setError(error);
    
                    // Track retry status
                    await trackSearchStatus('retry');
                    throw error; // Ensure rejection for Promise.race to continue
                } finally {
                    if (pageIndex === 1) {
                        setLoading(false);
                    } else {
                        setLoadingMore(false);
                    }
                }
            };
    
            while (retryCount < maxRetries) {
                try {
                    // Send 5 concurrent requests and process the fastest one
                    await Promise.race([
                        makeFetchRequest(),
                    ]);
    
                    // If the race succeeds, exit the retry loop
                    return;
                } catch (error) {
                    retryCount += 1;
                    if (retryCount < maxRetries) {
                        console.warn(`Retrying... (${retryCount}/${maxRetries})`);
                        await delay(1000); // A short delay before retrying
                    } else {
                        console.error('Error');
                        await trackSearchStatus('error');
                        break;
                    }
                }
            }
        };
    
        fetchWithRetry();
    }, [products, setProducts, setLoading, setError, minPrice, maxPrice]);
    
    const loadMoreProducts = useCallback(() => {
        if (!loadingMore) {
            setPageIndex(prevIndex => {
                const nextIndex = prevIndex + 1;
                fetchProducts(searchQuery, nextIndex);
    
                return nextIndex;
            });
        }
    }, [searchQuery, loadingMore, fetchProducts]);
    
    

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const handleSearch = useCallback((query) => {
        const searchValue = query || searchQuery;
        if (searchValue.trim() !== "") {
            console.log('Search triggered for:', searchValue);
            setProducts([]);
            setPageIndex(1);
            fetchProducts(searchValue, 1);
        } else {
            console.log('Search query is empty. No request will be made.');
        }
    }, [searchQuery, fetchProducts]);

    const fetchProductImages = async (productUrl) => {
        const apiUrl = 'https://engine1-f36f7fb18f56.herokuapp.com/vision/inteligentvision/';
        const numConcurrentRequests = 1;
        const retryDelay = 5; // 5 seconds delay for retry
    
        // Helper function to fetch data with abort functionality
        const fetchWithAbort = async (controller) => {
            try {
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ url: productUrl }),
                    signal: controller.signal // Pass abort signal to request
                });
    
                const data = await response.json();
    
                if (data.images && data.images.length > 0) {
                    return data.images; // Return the images if found
                } else {
                    throw new Error('No images found'); // Throw error if no images
                }
            } catch (error) {
                if (error.name !== 'AbortError') {
                    console.error('Request failed:', error);
                }
                return null; // Return null in case of failure
            }
        };
    
        // Function to handle multiple concurrent requests and stop as soon as one returns valid data
        const fetchConcurrent = async () => {
            const controllers = []; // Store abort controllers to cancel remaining requests
    
            const requests = Array.from({ length: numConcurrentRequests }, () => {
                const controller = new AbortController();
                controllers.push(controller); // Store controller for later abortion
                return fetchWithAbort(controller); // Create fetch promises
            });
    
            for (const request of requests) {
                try {
                    const result = await request;
    
                    if (result && result.length > 0) {
                        console.log('Images found:', result);
    
                        // Abort all other ongoing requests
                        controllers.forEach(controller => controller.abort());
    
                        return result; // Return the images from the first successful request
                    } else if (result && result.length === 0) {
                        console.log('No images found, retrying in 5 seconds...');
                        await new Promise(resolve => setTimeout(resolve, retryDelay)); // Wait 5 seconds before retrying
                        return fetchConcurrent(); // Retry the fetch
                    }
                } catch (error) {
                    console.error('Error during fetch:', error);
                }
            }
    
            console.error('All requests completed with no valid responses.');
            return []; // Return empty array if no successful responses
        };
    
        // Start fetching with concurrent requests
        return fetchConcurrent();
    };
    
    
    
    
    const fetchProductDetails = async (productUrl, productName) => {
        try {
            const response = await fetch('https://engine1-f36f7fb18f56.herokuapp.com/nodesconnections/openai_api_nodesconnections/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ url: productUrl, product_name: productName }),
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                return data;
            } else {
                throw new Error(`Failed to fetch product details from ${response.url}`);
            }
        } catch (error) {
            console.error('Error fetching product details:', error);
            return null;
        }
    };

    const fetchProductSizes = async (productUrl) => {
        const apiUrl = 'https://engine1-f36f7fb18f56.herokuapp.com/cloud_network/groq_order/';
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ url: productUrl })
            });
            if (response.ok) {
                const data = await response.json();
                if (data.sizes && data.sizes.length > 0) {
                    setAvailableSizes(data.sizes); 
                    return data.sizes;
                } else {
                    throw new Error('No sizes found');
                }
            } else {
                throw new Error(`Failed to fetch sizes: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error fetching product sizes:', error);
            return [];
        }
    };

    const fetchProductImagesAndDetails = async (productUrl, productName) => {
        setImageLoading(true);
        const imagesPromise = fetchProductImages(productUrl);
        const detailsPromise = fetchProductDetails(productUrl, productName);

        imagesPromise.then(images => {
            if (images.length > 0) {
                setSelectedProduct((prevProduct) => ({
                    ...prevProduct,
                    images: [...new Set(images)],
                }));
                setImageLoading(false);
                setDescriptionLoading(false);
            }
        });

        detailsPromise.then(details => {
            if (details && details.description) {
                setSelectedProduct((prevProduct) => ({
                    ...prevProduct,
                    description: details.description,
                    color: details.color || '',
                }));
                setDescriptionLoading(false);
            }
        });

        try {
            const [images, details] = await Promise.all([imagesPromise, detailsPromise]);
            return { images, details };
        } catch (error) {
            console.error('Error fetching product images and details:', error);
            setImageLoading(false);
            return { images: [], details: null };
        }
    };

    const handleProductClick = async (product) => {
        setSelectedProduct({
            ...product,
            images: [product.cover_image],
        });
        setCurrentImageIndex(0);
        setShowProductDetail(true);
        setDescriptionLoading(true);

        setFullScreenHeight('10vh');

        setTimeout(() => {
            setFullScreenHeight('100vh');
        }, 10);

        fetchProductImagesAndDetails(product.product_page, product.name);
        fetchProductSizes(product.product_page); 
    };

    const handleAddToCart = () => {
        const sessionToken = localStorage.getItem('sessionToken');
        const userID = localStorage.getItem('userID');
        const tokenData = sessionToken ? JSON.parse(sessionToken) : null;
        const isLoggedIn = tokenData && tokenData.expiryTime > Date.now() && userID;
    
        if (!isLoggedIn) {
            localStorage.setItem('searchengine_login', false);
            setShowLogin(true);
            return;
        }
    
        if (availableSizes.length > 0 && !selectedSize) {
            setAddToCartError(true);
            setTimeout(() => setAddToCartError(false), 5000);
            return;
        }
    
        setIsAddingToCart(true);
    
        if (isLoggedIn && selectedProduct && selectedProduct.price && selectedProduct.images[0]) {
            const productData = {
                user_id: userID,
                product_id: selectedProduct.id,
                product_name: `${selectedProduct.name} - Size: ${selectedSize}`,
                product_price: extractPriceForCart(selectedProduct.price),
                product_color: selectedProduct.color || '',
                product_size: selectedSize,
                product_description: selectedProduct.description || '',
                product_image: selectedProduct.images[0],
                product_url: selectedProduct.product_page,
            };
    
            fetch('https://customerserver1-5d81976997ba.herokuapp.com/addcart/add-to-cart/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // 'X-API-KEY': 'eiojwhd93reugh9euf0ehdovivfkcjgfheodfoeoigjeuffhuhooh',
                },
                body: JSON.stringify(productData),
            })
            .then(response => {
                if (response.ok) {
                    return response.json().then(() => {
                        // Auto click the button after successfully adding to cart
                        document.querySelector('.__cartButton__4ej9f').click();
                    });
                } else {
                    throw new Error('Failed to add to cart');
                }
            })
            .then(data => {
                console.log('Response from API:', data.message);
                setAddToCartSuccess(true);
                setTimeout(() => {
                    setAddToCartSuccess(false);
                }, 2000);
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally(() => {
                setIsAddingToCart(false);
            });
        }
    };
    

    const extractPriceForCart = useCallback((price) => {
        if (typeof price === 'string') {
            const numericString = price.replace(/[^\d,.-]/g, '').replace(',', '.');
            const numericPrice = parseFloat(numericString);
    
            if (isNaN(numericPrice)) {
                return '0.00';
            }
    
            return numericPrice.toFixed(2);
        } else if (typeof price === 'number') {
            return price.toFixed(2);
        } else {
            return '0.00';
        }
    }, []);

    const extractPrice = useCallback((price) => {
        if (typeof price === 'number') {
            return `${new Intl.NumberFormat('sv-SE').format(price.toFixed(2))} kr`;
        } else if (typeof price === 'string') {
            const numericString = price.replace(/[^\d,.-]/g, '').replace(',', '.');
            const numericPrice = parseFloat(numericString);
    
            if (isNaN(numericPrice)) {
                return '0.00 kr';
            }
    
            return `${new Intl.NumberFormat('sv-SE').format(numericPrice.toFixed(2))} kr`;
        } else {
            return '0.00 kr';
        }
    }, []);

    const generateRandomClassName = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const handleCloseProductDetail = () => {
        setFullScreenHeight('0vh');
        setTimeout(() => {
            setShowProductDetail(false);
            setAvailableSizes([]);  // Clear available sizes
            setKluretResponse('');  // Clear Kluret response
            setSelectedSize(''); // Clear selected size
        }, 500);
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === selectedProduct.images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? selectedProduct.images.length - 1 : prevIndex - 1
        );
    };

    const handleCartClick = () => {
        setShowCart(true);
        setTimeout(() => {
            setCartHeight('100vh');
        }, 10);
    };

    const handleCloseCart = () => {
        setCartHeight('0vh');
        setTimeout(() => {
            setShowCart(false);
        }, 500);
    };

    const handleAddToWishlist = async () => {
        setIsAddingToWishlist(true);
        setTimeout(() => {
            setIsAddingToWishlist(false);
        }, 2000);
    };

    const handleCloseLogin = () => {
        setShowLogin(false);
    };

    const truncateProductName = (name) => {
        const words = name.split(' ');
        if (words.length > 5) {
            return words.slice(0, 5).join(' ') + '...';
        }
        return name;
    };

    const truncateDescription = (description, wordLimit) => {
        if (!description || typeof description !== 'string') {
            return { truncated: '', full: '' };
        }

        const words = description.split(' ');
        if (words.length > wordLimit) {
            return {
                truncated: words.slice(0, wordLimit).join(' ') + '...',
                full: description,
            };
        }
        return { truncated: description, full: description };
    };

    const getFilterStyle = () => {
        return {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
            width: screenWidth < 700 ? '95%' : '50%',
            height: 'fit-content',
            background: 'rgba(0, 0, 0, 0.85)',
            padding: '20px',
            borderRadius: '10px',
            display: showFilters ? 'block' : 'none',
            overflowY: 'auto',
            color: 'white',
        };
    };

    const handleSaveAndApply = () => {
        console.log('Filters applied with the following range:', minPrice, maxPrice);
        toggleFilters();
        setProducts([]); // Clear the current product list
        setPageIndex(1);  // Reset to first page
        fetchProducts(searchQuery, 1);  // Trigger a new request with updated filter inputs
    };

    useEffect(() => {
        const handleScroll = () => {
            if (listRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = listRef.current;
                if (scrollTop + clientHeight >= scrollHeight - 5 && !loading && !loadingMore) {
                    loadMoreProducts();
                }
            }
        };

        const ref = listRef.current;
        if (ref) {
            ref.scrollTop = ref.scrollTop;
            ref.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (ref) {
                ref.removeEventListener('scroll', handleScroll);
            }
        };
    }, [loading, loadingMore, loadMoreProducts, searchQuery]);

    // New effect to check if searchengine_login is true and auto-close login popup
    useEffect(() => {
        const intervalId = setInterval(() => {
            const isLoggedIn = localStorage.getItem('searchengine_login');
            if (isLoggedIn === 'true') {
                handleCloseLogin();
                localStorage.removeItem('searchengine_login');
                clearInterval(intervalId);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [showLogin]);

    const handleUserInputSubmit = async () => {
        if (userInput.trim() !== "") {
            setTyping(true);
            setKluretResponse('');

            const productInfo = {
                name: selectedProduct ? selectedProduct.name : '',
                description: selectedProduct ? selectedProduct.description : '',
            };

            try {
                const response = await fetch('https://webnodes-067775909937.herokuapp.com/product_suport_google/userinput/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        prompt: userInput,
                        product_info: productInfo
                    }),
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log(data);

                    setKluretResponse(data.response.trim());
                    setTyping(false);
                } else {
                    const errorMessage = `Failed to get a response from the server. Status code: ${response.status}`;
                    console.error(errorMessage);
                    setKluretResponse(errorMessage);
                    setTyping(false);
                }
            } catch (error) {
                const errorMessage = `An error occurred: ${error.message}`;
                console.error(errorMessage);
                setKluretResponse(errorMessage);
                setTyping(false);
            }
        }
    };

    return (
        <>
            <div className="jihugyutjhkhjdgfudkjfbkfgjkbdsf" ref={divRef}>
                <div className='ojihoguiyw9s0ug8he0rjsihugiwfujsdfopgho'>
                    <div className='ihuw9gy8uihorodgijh99wr0gojvih9rh'>
                        <div className='oihuiydjfkhurfuhgeorhifg9shoigheuih42wf'>
                            <div className='djiugyftyg9y90uyhigfuygihoihugyftuhjhgy'>
                                <input
                                    ref={inputRef}
                                    type="text"
                                    placeholder="Enter the product"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="w-full p-2 rounded-md border border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-0"
                                    style={{ fontSize: '16px' }}
                                />

                                <span className='jihuiyufygiougiyfuyguiooiohugyfusi' onClick={() => handleSearch()}>
                                    <img src={searchengine_svg} width="23px" alt="" />
                                </span>
                            </div>
                            
                            <it 
                                className='_____i3jwdiogerjfge9fdjkgojoe__edwiofo'
                                onClick={toggleFilters}
                            >
                                <img src={filter_svg} className='iosuhseurifgetjhighiutegioofggie_iwfiugeifu__fjnfnj' alt="" />
                            </it>

                            <it onClick={onClose} className='jiuyfguhihguyehfhugyrfuhiewerr3332'>
                                <img src={close_svg} width="30px" alt="" />
                            </it>
                            
                        </div>
                    </div>
                </div>
                {loading && !loadingMore && (
                    <div className="flex justify-center items-center mt-4">
                        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                    </div>
                )}
                {products.length > 0 ? (
                    <>
                        <div ref={listRef} className='product_list overflow-y-scroll scrollbar-hide'>
                            {products.map((product, index) => {
                                const randomClassName = generateRandomClassName(Math.floor(Math.random() * (40 - 20 + 1)) + 20);
                                return (
                                    <div
                                    key={product.id}
                                    className={`ngouyufcgivouhipjohguyfmx-auto mt-11 transform overflow-hidden rounded-lg bg-white duration-300 hover:scale-105 ${randomClassName}`}
                                    id='jdhy9548thujdihbeukoihugyf8t8g9'
                                    onClick={() => handleProductClick(product)}
                                >
                                    <div className='ousdksjhbufskghejdoifkefgotuifhe0htroifjedfuoekjdv0ehifv'>
                                        <div
                                            className='oii0hwsd3perfkoji309rigk3jgr0geriofkjd3ge98ugh8tre__tej9g40egf_____ojefwd9f'
                                            style={{
                                                backgroundImage: `url(${product.cover_image})`,
                                                backgroundSize: 'contain',
                                                backgroundPosition: 'center',
                                                height: '100%',
                                                width: '100%',
                                                backgroundRepeat: 'no-repeat',
                                            }}
                                            id={`product-image-${index}`}
                                        >
                                        </div>
                                    </div>

                                    <div className="p-0" id='sfdoh39rwjdhgfygyeuhsjghery8__w8rhsu__dueuuuu'>
                                        <h2 className="mb-2 text-lg font-small dark:text-white text-white-900">{truncateProductName(product.name)}</h2>
                                        <div className="" id='oihugyftdut7897r68dtfyuhiug9y'>
                                            <p className="mr-2 text-lg font-big text-blue-1000 dark:text-white">{extractPrice(product.price)}</p>
                                            <a>
                                                <img src={openai_svg} className='openai_svg_d2uihdurwrgjh' alt="" />
                                            </a>
                                        </div>
                                        <div className='klarna-price-container' id='klarna-price-container'>
                                            <div className='__klarna_monthly__pay_9wje49e'>
                                                {(() => {
                                                    const price = parseFloat(product.price.replace(/[^\d.-]/g, '')); 
                                                    return !isNaN(price) ? (price / 6).toFixed(0) + 'kr /m' : '0.00 kr';
                                                })()}
                                                
                                                <img src="https://www.svgrepo.com/show/508697/klarna.svg" width="2px" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                );
                            })}
                        </div>
                        <div className="" id='wjodsfierihogejifeksfohuewgyiodskfhl__odejof'>
                            <button
                                onClick={loadMoreProducts}
                                className="wjodsfierihogejifeksfohuewgyiodskfhl"
                                disabled={loadingMore}
                            >
                                {loadingMore ? (
                                    <svg
                                        className="animate-spin h-5 w-5 text-white mx-auto"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                ) : (
                                    "Load More"
                                )}
                            </button>
                        </div>
                    </>
                ) : (
                    !loading && !error && (
                        <div className='oiuihodfrudjfkjhoi3rwu9dibjgwrh9u3gidbjvo'>
                            <img src={kluret_searchenginenetwork} width="40px" alt="" />
                        </div>
                    )
                )}
                {error && (
                    <div className="error">
                        <div className='__diverror__39jf'>
                            <div>
                                <p>Kluret Network Error</p>
                                <p>Please Retry</p>
                            </div>
                            <button onClick={() => handleSearch()} className='__pleaseretry__39jef'>Retry</button>
                        </div>
                    </div>
                )}
            </div>

            {showProductDetail && selectedProduct && (
                <div className="fixed inset-0 z-50 bg-white dark:bg-slate-800 transition-all duration-500 ease-in-out"
                     style={{ height: fullScreenHeight }}>
                    <div className="relative h-full overflow-y-scroll scrollbar-hide" id='jwioduhferusfihgkkoesudgkl____ewiedjsfuh3__widsf'>
                        <div className='kojbiwfhriofb2r9fh4brjdfbicw8ycubirhocir'>
                            <div className='iodui2yf4uhrjiuwyfhhufr'>
                                <button onClick={handleCloseProductDetail}>
                                    <img src={close_svg} width="25px" alt="Close" />
                                </button>
                                <button onClick={handleCartClick} className='__cartButton__4ej9f'>
                                    <img src={cart_svgg} width="27px" alt="Cart" />
                                </button>
                            </div>
                        </div>

                        <div className="dark:bg-white-800" id="jiwoshdgheursihfgkljpeorfhgilnsd__jwrhg83rj">
                            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-0" id="jwsjdgheuir______nowohsdg3uirejghbcibh">
                                <div className="flex flex-col md:flex-row -mx-4">
                                    <div className="md:flex-1 px-4">
                                    <div
                                    className="w-full h-[400px] rounded-lg mb-4 pt-5 relative flex items-center justify-center overflow-hidden"
                                    style={{
                                        maxWidth: '100%',
                                        width: '100%',
                                        height: '400px',
                                        backgroundImage: selectedProduct.images && selectedProduct.images.length > 0 
                                            ? `url(${selectedProduct.images[currentImageIndex]})` 
                                            : 'none',
                                        backgroundSize: 'contain',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                    }}
                                    id="ohulhkforlsiufghoegliurjf___________lfuhffskduhrofkg_________fjwobf"
                                >
                                </div>


                                        <div 
                                            className="flex overflow-x-auto no-scrollbar -mx-2 mb-4" 
                                            id="jiwuhsiofesgljhogfieyskdif___________iifdbw"
                                            style={{ whiteSpace: 'nowrap' }}
                                        >
                                            {selectedProduct.images && selectedProduct.images.length > 0 && selectedProduct.images.map((image, index) => (
                                                <div 
                                                    key={index} 
                                                    className="inline-block w-1/5 px-2"
                                                    id='iuwohdsijfkwwajwrifd90fiwreks_____jgeijfe'
                                                    style={{ flex: '0 0 auto' }} 
                                                >
                                                    <img
                                                        src={image}
                                                        alt={`${selectedProduct.name} ${index + 1}`}
                                                        className={`cursor-pointer rounded-lg h-16 w-full object-cover object-center ${currentImageIndex === index ? 'border-2 border-blue-500' : ''}`}
                                                        onClick={() => setCurrentImageIndex(index)}
                                                    />
                                                </div>
                                            ))}

                                            {imageLoading && (
                                                <div className="text-center text-gray-600 dark:text-gray-300">
                                                    <div className="flex justify-center items-center mt-4">
                                                        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="">
                                            <div className="oijjuikukhjwokpioiugvhbjkjohiugvhhgvhghh">
                                                <button
                                                    className={`${
                                                        addToCartError ? 'bg-red-500 animate-shake' : ''
                                                    }`}
                                                    onClick={handleAddToCart}
                                                    disabled={isAddingToCart}
                                                    id="iuigwyiofhwesfslio___button__23333"
                                                >
                                                    {isAddingToCart ? (
                                                        <svg
                                                            className="animate-spin h-5 w-5 text-white mx-auto"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <circle
                                                                className="opacity-25"
                                                                cx="12"
                                                                cy="12"
                                                                r="10"
                                                                stroke="currentColor"
                                                                strokeWidth="4"
                                                            ></circle>
                                                            <path
                                                                className="opacity-75"
                                                                fill="currentColor"
                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                            ></path>
                                                        </svg>
                                                    ) : addToCartSuccess ? 'Done' : "Add to Cart"}
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="md:flex-1 px-4">
                                        <h2 className="text-2xl font-bold mb-2" id="iwjhdujgkhkeiugfh3reu_ejwnfuuerhf__ewifd">
                                            {selectedProduct.name}
                                        </h2>
                                        <div className="flex mb-4">
                                            <div className="mr-4">
                                                <span className="wioshuygyiiufgpeor___iwjshgirs__12344kefmwjsi">{extractPrice(selectedProduct.price)}</span>
                                            </div>
                                        </div>

                                        {availableSizes.length > 0 && (
                                            <div id='__selectsize___styliing'>
                                                <select
                                                    id="_____product_sizes___init"
                                                    className="p-2 cursor-pointer bg-black dark:bg-gray-700 dark:text-white border border-gray-300 rounded"
                                                    value={selectedSize}
                                                    onChange={(e) => setSelectedSize(e.target.value)}
                                                >
                                                    <option value="">Size</option>
                                                    {availableSizes.map((size, index) => (
                                                        <option key={index} value={size}>
                                                            {size}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}

                                        <div className="payments___idwdhusrdfsgiaor9f9uszoifx">
                                            <div>
                                                <img src="https://www.svgrepo.com/show/508697/klarna.svg" width="40px" alt="" />
                                            </div>
                                            <div>
                                                <img src="https://www.svgrepo.com/show/266087/master-card.svg" width="40px" alt="" />
                                            </div>
                                            <div>
                                                <img src="https://www.svgrepo.com/show/508402/apple-pay.svg" width="40px" alt="" />
                                            </div>
                                            <div>
                                                <img src="https://www.svgrepo.com/show/508690/google-pay.svg" width="40px" alt="" />
                                            </div>
                                        </div>


                                        <div className="ojwrsudfjekrfsgboerisgdoiweusfgiojsrfogjisf">
                                            <div className="jiowsdhfwosduiwjarhsoguwlrhpfgeoshfogieeeeeewfhs">
                                                <img src={leveranse_svg} width="30px" alt="" />
                                                <p className="jiwshofnjwershfgefodigjhefpppppppppppppppppppp">Delivery</p>
                                            </div>
                                            <div>
                                                <p className="jfiwdsoefdhgoeidfjhbusdioxfljkndbibxuhvockjdonjvbiodx">3-5 Working Days</p>
                                            </div>
                                        </div>

                                        <div className="kjdouhfjshuiwpoieuahdofkjwsid________isjds">
                                            {descriptionLoading ? (
                                                <div className="flex justify-center items-center mt-4">
                                                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                                                </div>
                                            ) : (
                                                <div
                                                    className="texwijosdhuhferofskhfqwjaoruirjgkd_______wdiufh9349444fhsdi"
                                                    dangerouslySetInnerHTML={{ __html: selectedProduct.description }}
                                                ></div>
                                            )}
                                            {selectedProduct.description && truncateDescription(selectedProduct.description, 30).truncated !== selectedProduct.description && (
                                                <button
                                                    onClick={() => setShowFullDescription(!showFullDescription)}
                                                    className="text-blue-500 hover:underline mt-2"
                                                >
                                                </button>
                                            )}
                                        </div>
                                        

                                        <div className="owhrfuoghs03rw8fhuwgoerisfhegho9uf">
                                            <div>
                                                <p className="wiudfhoeufhgore__________ijwejfiwiffifiii">Kluret Produktassistans</p>
                                            </div>
                                            <div className="kis0dhjwrsjdiowsrfjoifsfffffoefijofoofoeo">
                                                <div className="oiwuosieeshufscogikejifuhogsjuiyiusfuufuufu">
                                                    <input
                                                        type="text"
                                                        placeholder="Fråga om produkten"
                                                        value={userInput}
                                                        onChange={(e) => setUserInput(e.target.value)}
                                                    />
                                                    <span
                                                        className="send__doijefowefhrhwifjjwrofiwj"
                                                        onClick={() => {
                                                            handleUserInputSubmit();
                                                            setUserInput('');  // Clear the input field after submission
                                                        }}
                                                    >
                                                        →
                                                    </span>
                                                </div>
                                            </div>
                                        

                                            <div className="jiwosdh0igwr90sigeitrggtggggggggr05555555555555fijworfepi">
                                                <p className="hsugh0osifeg0isrofhikeh9dhg8rt8888t88g8t8t8">Kluret Svar</p>
                                                <div className="outputomkluret___39jrehfijrwidkfjd">
                                                    {typing ? (
                                                        <div className="flex justify-center items-center">
                                                            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                                                        </div>
                                                    ) : (
                                                        <p>{kluretResponse || '>'}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        

                                        <div className='___payasyougo___ejf49jfd9of'>
                                            <div className='__tititle__de9jd9fwr'>
                                                <h1>Klarna FlexiPay</h1>
                                                <img src={payasyougo_svg} alt="" />
                                            </div>
                                            <div className='__chooseoption__39erhfd'>
                                                <div className='__chooseoption__39erhfd_eifdji'>
                                                    <div>
                                                        <button
                                                            className={`${selectedPaymentPlan === '30-days' ? 'bg-black text-white' : ''}`}
                                                            onClick={() => setSelectedPaymentPlan('30-days')}
                                                        >
                                                            30-days
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button
                                                            className={`${selectedPaymentPlan === '24-months' ? 'bg-black text-white' : ''}`}
                                                            onClick={() => setSelectedPaymentPlan('24-months')}
                                                        >
                                                            24-Months
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button
                                                            className={`${selectedPaymentPlan === '36-months' ? 'bg-black text-white' : ''}`}
                                                            onClick={() => setSelectedPaymentPlan('36-months')}
                                                        >
                                                            36-Months
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button
                                                            className={`${selectedPaymentPlan === 'pay-now' ? 'bg-black text-white' : ''}`}
                                                            onClick={() => setSelectedPaymentPlan('pay-now')}
                                                        >
                                                            Pay Now
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button
                                                            className={`${selectedPaymentPlan === 'invoice' ? 'bg-black text-white' : ''}`}
                                                            onClick={() => setSelectedPaymentPlan('invoice')}
                                                        >
                                                            Invoice
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='__instractions__ifehdgowjed'>
                                                <div>
                                                    <div className='___typeofpayment__9jefdg'>
                                                        <h1>{selectedPaymentPlan === '30-days' ? 'After 30-Days' : selectedPaymentPlan?.replace('-', ' ')}</h1>
                                                    </div>
                                                    <ul>
                                                        {selectedPaymentPlan === '30-days' && (
                                                            <>
                                                                <li>Pay full price of {parseFloat(selectedProduct.price.replace(/[^0-9.-]+/g,"")).toFixed(2)}kr after 30 days.</li>
                                                            </>
                                                        )}
                                                        {selectedPaymentPlan === '24-months' && (
                                                            <>
                                                                <li>24 monthly payments of {(parseFloat(selectedProduct.price.replace(/[^0-9.-]+/g,"")) / 24).toFixed(2)}kr</li>
                                                                <li>Total payment over 24 months: {parseFloat(selectedProduct.price.replace(/[^0-9.-]+/g,"")).toFixed(2)}kr</li>
                                                            </>
                                                        )}
                                                        {selectedPaymentPlan === '36-months' && (
                                                            <>
                                                                <li>36 monthly payments of {(parseFloat(selectedProduct.price.replace(/[^0-9.-]+/g,"")) / 36).toFixed(2)}kr</li>
                                                                <li>Total payment over 36 months: {parseFloat(selectedProduct.price.replace(/[^0-9.-]+/g,"")).toFixed(2)}kr</li>
                                                            </>
                                                        )}
                                                        {selectedPaymentPlan === 'pay-now' && (
                                                            <>
                                                                <li>Pay the full price of {parseFloat(selectedProduct.price.replace(/[^0-9.-]+/g,"")).toFixed(2)}kr immediately</li>
                                                            </>
                                                        )}
                                                        {selectedPaymentPlan === 'invoice' && (
                                                            <>
                                                                <li>Receive an invoice and pay {parseFloat(selectedProduct.price.replace(/[^0-9.-]+/g,"")).toFixed(2)}kr within 14 days.</li>
                                                            </>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>


                                        {/* <div className='___klarnapayclauclations__9erj9f4j'>
                                            <div className='__inner_dsjjejr4e____klarnapayclauclations__9erj9f4j'>
                                                <div>
                                                    <h1>Kluret AI || Klarna Pay</h1>
                                                    <img src={kluret_klarna_pay_svg} alt="" />
                                                </div>
                                            </div>
                                        </div> */}


                                        <div className="omkluret____iwehrgeuhif">
                                            <div className="____parthnerdsjoioqdeu">
                                                <span>
                                                    <img src={openai_svg} width="25px" alt="" />
                                                </span>
                                                <span>
                                                    <img src={google_svg} width="25px" alt="" />
                                                </span>
                                                <span>
                                                    <img src={stripe_svg} width="25px" alt="" />
                                                </span>
                                                <span>
                                                    <img src="https://www.kluret.se/static/media/logo.743829c09361c1dec1a7684a9e0634f4.svg" width="25px" alt="" />
                                                </span>
                                            </div>
                                        </div>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                </div>
            )}

            {showCart && (
                <div className="fixed inset-0 z-50 bg-black dark:bg-slate-800 transition-all duration-500 ease-in-out overflow-y-auto"
                    style={{ height: '100vh', width: '100%' }}>
                    <div className="h-full w/full" id='jiou9hsud___2__4__iohr9fugrw9o'>
                        <CartTemplate onClose={handleCloseCart} />
                    </div>
                </div>
            )}

            {showLogin && (
                <div className="fixed inset-0 z-50 bg-black dark:bg-slate-800 flex justify-center items-center" style={{ height: '100vh', width: '100%' }}>
                    <div className="relative w/full h/full flex items-center justify-center">
                        <AuthPage className="w/full" />
                        <button onClick={handleCloseLogin} className='ihuuvklwhosfikrslihejbshkejohvdkfhbjd'>
                            <img src={close_svg} width="25px" alt="Close" />
                        </button>
                    </div>
                </div>
            )}

            {showFilters && (
                <div style={getFilterStyle()} className='ojihouighdfwjrisuhwjpsioudhfwros'>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <img src={close_svg} width="20px" alt="Close" onClick={toggleFilters} style={{ cursor: 'pointer' }} />
                    </div>
                    
                    <div className='ojjihuygikhfjieiudohfiowiruhjdhfjiw___ijedfijw'>
                        <div className='jowudjiferujsksjdfoiwrus___sklllldlfll333'>
                            {/* Minimum Price Input */}
                            <div className='kwjodubfwjijsfugsofikrkrkr__1234we3'>
                                <div>
                                    <input 
                                        type="number" 
                                        value={minPrice} 
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value === '') {
                                                setMinPrice(''); // Allow the user to clear the input
                                            } else {
                                                const numericValue = parseInt(value, 10);
                                                if (!isNaN(numericValue)) {
                                                    setMinPrice(numericValue > 10000 ? 10000 : numericValue); // Clamp to max 10000
                                                }
                                            }
                                        }} 
                                        onBlur={(e) => {
                                            if (e.target.value === '' || parseInt(e.target.value, 10) < 1) {
                                                setMinPrice(1); // Reset to minimum value
                                            }
                                        }} 
                                        className="no-spin w/full p-2 rounded-md border border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-0"
                                    />
                                </div>
                                <div>
                                    <p>Kr</p>
                                </div>
                            </div>

                            {/* Maximum Price Input */}
                            <div className='kwjodubfwjijsfugsofikrkrkr__1234we3'>
                                <div>
                                    <input 
                                        type="number" 
                                        value={maxPrice} 
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value === '') {
                                                setMaxPrice(''); // Allow the user to clear the input
                                            } else {
                                                const numericValue = parseInt(value, 10);
                                                if (!isNaN(numericValue)) {
                                                    setMaxPrice(numericValue > 10000 ? 10000 : numericValue); // Clamp to max 10000
                                                }
                                            }
                                        }} 
                                        onBlur={(e) => {
                                            if (e.target.value === '' || parseInt(e.target.value, 10) < 1) {
                                                setMaxPrice(1); // Reset to minimum value
                                            }
                                        }} 
                                        className="no-spin w/full p-2 rounded-md border border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-0"
                                    />
                                </div>
                                <div>
                                    <p>Kr</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='ojiousidofhkefjsgujeijfsliuesdvoxklhjeodfbgohirjeufs'>
                        <span 
                            className='okkwpsijfohhcgkjerishgopcsjkxoifgucesbodzgkxgjco'
                            onClick={handleSaveAndApply}
                        >
                            Save and Apply
                        </span>
                    </div>
                </div>
            )}
        </>
    );
}

export default SearchEgine;
